<template>
  <div class="hotTableComponent" :class="classMain" tabindex="0">
    <div ref="hotTableComponent"></div>
  </div>
</template>
<script>
import Handsontable from "handsontable";
import FieldMixin from "./FieldMixin.vue";

export default {
  inject: ["rformc", "app"],
  mixins: [FieldMixin],
  props: {
    /*rform: {},
    name: { type: String },
    required: { type: Boolean },
    readonly: { type: Boolean },
    searchable: { type: Boolean, default: false },*/
    columns: { type: Array },
    colHeaders: { type: Array },
    height: { type: Number, default: 225 },
    width: { type: Number, default: 650 },
    structureRef: { type: String },
    minRows: { type: Number },
    htSettings: { type: Object },
    primary: { type: String, default: "id" },
    fields: { type: Array, default: () => [] },
    allowHideColumns: { type: Boolean, default: true },
    allowDeleteRows: { type: Boolean, default: true },
  },
  computed: {
    classMain: function () {
      var classMain = {};
      classMain["readonly"] =
        this.readonly ||
        this.form.mode == "view" ||
        this.form.mode == "empty" ||
        (this.form.mode == "search" && !this.searchable); //this.readonly || this.form.readonly;
      classMain["mode-" + this.form.mode] = true;
      classMain["wrong"] = this.name in this.form.errors;
      return classMain;
    },
    colHeadersHt() {
      /*return this.colHeaders
        ? this.colHeaders
        : this.columnsData.map(c => c.header);*/
      var self = this;
      return (
        this.colHeaders ||
        function (col) {
          if (typeof self.columnsData[col] == "undefined") return "-";
          let h = self.columnsData[col];
          return (
            "<span title='" +
            (h.help || "") +
            "'>" +
            (h.header || h.name) +
            "</span>"
          );
        }
      );
    },
    colWidthsHt() {
      return this.columnsData.map((c) => c.width || 100);
    },
    columnsHt() {
      var self = this;
      var cols = [];
      for (let c of self.columnsData) {
        var col = { ...c, ...{ data: c.name } };
        //var col = { data: c.name };
        //if (c.validator) col.validator = c.validator;
        const undefinedVal = (v, d) => typeof v == 'undefined' ? d : v; 
        switch (c.widget) {
          case "checkbox":
            col.type = "checkbox";
            col.checkedTemplate = typeof col.checkedTemplate == 'undefined' ? 1 : col.checkedTemplate; 
            col.uncheckedTemplate = typeof col.uncheckedTemplate == 'undefined' ? 0 : col.uncheckedTemplate; 
            col.allowInvalid = false;
            col.allowEmpty = false;
            break;
          case "numeric":
            col.type = "numeric";
            if (c.format) {
              col.numericFormat = {
                pattern: c.format,
                culture: "es-ES",
              };
            }
            col.sortFunction = function (sortOrder) {
              return function (a, b) {
                if (sortOrder) return parseFloat(a[1]) >= parseFloat(b[1]);
                else return parseFloat(a[1]) <= parseFloat(b[1]);
              };
            };
            col.language = "es-ES";
            break;
          case "date":
            col = {
              data: c.name,
              type: "date",
              dateFormat: "YYYY-MM-DD",
              correctFormat: true,
              //defaultDate: '01/01/1900',
              // datePicker additional options (see https://github.com/dbushell/Pikaday#configuration)
              datePickerConfig: {
                // First day of the week (0: Sunday, 1: Monday, etc)
                firstDay: 1,
                showWeekNumber: true,
                i18n: {
                  previousMonth: "Mes anterior",
                  nextMonth: "Mes siguiente",
                  months: [
                    "Enero",
                    "Febrero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Agosto",
                    "Septiembre",
                    "Octubre",
                    "Noviembre",
                    "Diciembre",
                  ],
                  weekdays: [
                    "Domingo",
                    "Lunes",
                    "Martes",
                    "Miércoles",
                    "Jueves",
                    "Viernes",
                    "Sábado",
                  ],
                  weekdaysShort: [
                    "Dom",
                    "Lun",
                    "Mar",
                    "Mié",
                    "Jue",
                    "Vie",
                    "Sáb",
                  ],
                },
              },
              renderer: function (
                instance,
                td,
                row,
                col,
                prop,
                value,
                cellProperties
              ) {
                if (value) {
                  let padLeft = function (n) {
                    return ("00" + n).slice(-2);
                  };
                  let d = new Date(value);
                  value = [
                    padLeft(d.getDate()),
                    padLeft(d.getMonth() + 1),
                    d.getFullYear(),
                  ].join("/");
                }
                Handsontable.renderers.TextRenderer.apply(this, arguments);
                td.textContent = value;
                return td;
                //Handsontable.renderers.DateRenderer.apply(this, arguments);
              },
              sortFunction: function (sortOrder) {
                return function (a, b) {
                  if (sortOrder) return a[1] >= b[1];
                  else return a[1] <= b[1];
                  // sortOrder: If true, the order is ascending, if false - descending. undefined = original order
                  // a, b: Two compared elements. These are 2-element arrays, with the first element being the row index, the second - cell value.
                };
              },
            };
            break;
          case "select":
            col.editor = "select";
            col.selectOptions = c.options;
            if (!window.$.isArray(col.selectOptions)) {
              col.renderer = function (
                instance,
                td,
                row,
                col,
                prop,
                value,
                cellProperties
              ) {
                if (value) {
                  value = col.selectOptions[value];
                }
                Handsontable.TextCell.renderer.apply(this, arguments);
              };
            }
            break;
          case "percentage":
            col.className = "htRight";
            col.renderer = function (
              instance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) {
              /*if(value>100) value=100;
                    if(value<0) value=0;*/
              //value = Math.round(value * 100) / 100;
              if (!isFinite(value) || value == null) {
                value = null;
              } else if (!value) {
                value = "0%";
              } else {
                value = parseFloat(value);
                value = value.toFixed(2);
                value = value + "%";
              }
              Handsontable.NumericCell.renderer.apply(this, arguments);
            };
            break;
          case "color":
            col.editor = "color";
            col.renderer = function (
              instance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) {
              if (value) {
                var colorText = value; //'#'+invertHex(value.substr(1));
                window.$(td).css({ background: value, color: colorText });
              }

              Handsontable.TextCell.renderer.apply(this, arguments);
            };
            break;
          case "img": //TODO
            break;
          case "files": //TODO
            break;
          /*case "m2o":
          case "m2o_select2": //TODO
            col.editor = "autocomplete2";
            col.source = self.sourceM2o(c);
            col.handsontable = col.handsontable || {};
            col.handsontable.getValue = function() {
              var selection = this.getSelectedLast();
              if (!selection) return null;
              return this.getSourceDataAtRow(selection[0]);
            };
            col.handsontable.columns = [
              {
                data: c.primary || "id",
                renderer: function(
                  instance,
                  td,
                  row,
                  col,
                  prop,
                  value,
                  cellProperties
                ) {
                  let data = instance.getSourceDataAtRow(row);
                  td.innerHTML =
                    (c.image
                      ? "<img class='autocomplete2-image' src=" +
                        (self.$utils.misc.rget(data, c.image)
                          ? self.$utils.misc.rget(data, c.image)
                          : require("./../assets/noimage.png")) +
                        ">"
                      : "") +
                    (c.showCode
                      ? "<b class='autocomplete2-code'>" +
                        data[c.primary || "id"] +
                        "</b> "
                      : "") +
                    data[c.label || "nombre"];
                  return td;
                }
              }
            ];
            col.renderer = function(
              instance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) {
              //Handsontable.renderers.TextRenderer.apply(this, arguments);
              instance.cachedItems = instance.cachedItems || {};
              instance.cachedItems[prop] = instance.cachedItems[prop] || {};
              if (value && typeof value == "object") {
                instance.cachedItems[prop][value[c.primary || "id"]] = value;
                value = value[c.primary || "id"];
                //isntance.setDataAtRowProp();
              }
              if (
                value in instance.cachedItems[prop] &&
                instance.cachedItems[prop][value] == false
              ) {
                td.classList.add("wrong");
                td.setAttribute("title", "No existe el registro");
                td.textContent = value;
              } else {
                td.classList.remove("wrong");
                td.removeAttribute("title");
                if (value in instance.cachedItems[prop]) {
                  let data = instance.cachedItems[prop][value];
                  td.innerHTML =
                    (c.image
                      ? "<img class='autocomplete2-image' src=" +
                        (self.$utils.misc.rget(data, c.image)
                          ? self.$utils.misc.rget(data, c.image)
                          : require("./../assets/noimage.png")) +
                        ">"
                      : "") +
                    (c.showCode
                      ? "<b class='autocomplete2-code'>" +
                        data[c.primary || "id"] +
                        "</b> "
                      : "") +
                    data[c.label || "nombre"];
                  return td;
                } else if (value) {
                  instance.pendingCache = instance.pendingCache || {};
                  instance.pendingCache[prop] =
                    instance.pendingCache[prop] || [];
                  if (instance.pendingCache[prop].indexOf(value) === -1) {
                    instance.pendingCache[prop].push(value);
                    //Fetch
                    self.fetchData(instance, c, prop, value);
                  } else {
                    td.textContent = value;
                  }
                } else {
                  td.textContent = null;
                }
              }
              return td;
            };
            break;
          case "m2m_select2": //TODO
            break;*/
        }
        // Switch bueno
        switch (c.type) {
          case "files":
          case "m2m":
          case "m2o":
            col.self = self;
            break;
          case "checkbox":
            col.type = "checkbox";
            col.checkedTemplate = typeof col.checkedTemplate == 'undefined' ? 1 : col.checkedTemplate; 
            col.uncheckedTemplate = typeof col.uncheckedTemplate == 'undefined' ? 0 : col.uncheckedTemplate; 
            col.allowInvalid = false;
            col.allowEmpty = false;
            break;
          case "select":
            col.type = "text";
            col.editor = "select";
            if (c.options) col.selectOptions = c.options;
            if (!Array.isArray(col.selectOptions)) {
              col.renderer = function (
                instance,
                td,
                row,
                col,
                prop,
                value,
                cellProperties
              ) {
                if (value && c.options) value = c.options[value];
                Handsontable.renderers.TextRenderer.apply(this, [
                  instance,
                  td,
                  row,
                  col,
                  prop,
                  value,
                  cellProperties,
                ]);
              };
            }
            break;
          case "numeric":
            //if (col.numericFormat) col.numericFormat.culture = "es-ES";
            if (col.sufix) {
              col.renderer = function (
                instance,
                td,
                row,
                col,
                prop,
                value,
                cellProperties
              ) {
                Handsontable.renderers.NumericRenderer.apply(this, arguments);
                if (value) value = td.innerText + cellProperties.sufix;
                Handsontable.renderers.TextRenderer.apply(this, [
                  instance,
                  td,
                  row,
                  col,
                  prop,
                  value,
                  cellProperties,
                ]);
              };
            }
            break;
          case "color": //TODO
        }
        cols.push(col);
      }
      return cols;
    },
    computedGetValue() {
      return this.getValue() || [];
    },
    computedReadOnly() {
      let self = this;
      return self.readonly ||
        self.form.mode == 'view' ||
        self.form.mode == 'empty' ||
        (self.form.mode == 'search' && !self.searchable);
    }
  },
  data: function () {
    var self = this;
    return {
      form: this.rform ? this.rform : this.rformc,

      hotInstance: null,
      columnsData: [],// self.$utils.misc.clone(this.columns),
      renderH: self.$utils.misc.debounce(function () {
        self.hotInstance.render();
      }, 30, true),
      contextMenu: {
        items: {
          remove_row: {
            name: "Eliminar fila",
            disabled: function () {
              return (
                !self.allowDeleteRows ||
                (self.form.mode != "edit" && self.form.mode != "new")
              );
            },
            callback(key, selection, clickEvent) {
              let startRow = selection[0].start.row;
              let endRow = selection[0].end.row;
              let N = 1 + endRow - startRow;
              // Eliminar filas
              var val = self.hotInstance.getSourceData();
              val.splice(startRow, N);
              /*val = val.filter((v, i) =>
                Object.keys(v).some((el) => el != "_op" && v[el] != null)
              );*/
              let data = [...val];
              self.cleanData(data);
              self.setValue(data.length ? data : false);
              self.onChange();
              // Eliminar errores
              let errores = self.getContext(true, true)[self.getName()];
              let errores_copy = JSON.parse(JSON.stringify(errores || {}));
              for (let ie in errores) {
                if (ie >= startRow && ie <= endRow) {
                  delete errores[ie];
                } else if (ie > endRow) {
                  delete errores[ie];
                  errores[ie - N] = errores_copy[ie];
                }
              }
              self.$emit("removeRow");
            },
          },
          hide_column: {
            name: "Ocultar columna",
            disabled: function () {
              return !self.allowHideColumns;
            },
            callback: function (key, selection, clickEvent) {
              var h = this;
              let sel = h.getSelected()[0];
              let startCol = sel[1];
              self.columnsData.splice(startCol, 1);
            },
          },
          hsep1: "---------",
          save: {
            name: "Guardar estructura",
            disabled: function () {
              return !self.allowHideColumns;
            },
            callback: function () {
              self.saveTableStructure();
            },
          },
          restore: {
            name: "Restaurar estructura",
            disabled: function () {
              return !self.allowHideColumns;
            },
            callback: function () {
              self.restoreTableStructure();
            },
          },
        },
      },
      orderDir: null,
      loadedStructure: false,
      afterChange: function (changes, source) {
        let h = this;
        //window.console.log(source)
        //var self = this;
        self.setValueH();
        if (source == "loadData") {
          return;
        }
        //var h = self.$refs?.hotTableComponent.hotInstance;
        if (!h) return;

        let val = h.getSourceData();
        changes.forEach(function (change, i) {
          let row = change[0];
          if (val[row][self.primary]) val[row]._op = "put";
          else val[row]._op = "post";
        });
        //window.console.log(self.getValue(), h.getSourceData(), self.getValue() == h.getSourceData());

        self.onChange();
      },
      setValueH: self.$utils.misc.debounce(_ => {
        let self = this;
        let h = self.hotInstance;
        let data = [...h.getSourceData()];
        self.cleanData(data);
        self.preventLoadData = true;
        self.setValue(data.length ? data : false)
      }, 100),
      preventLoadData: false
    };
  },
  watch: {
    computedGetValue: function (v) {
      let self = this;
      if (!self.preventLoadData) {
        let data = self.getValue() || [];
        self.fitData(data);
        self.hotInstance.loadData(data);
      }
      self.preventLoadData = false;
    },
    columnsData: function (v) {
      let self = this;
      if (!self.hotInstance) return;
      self.hotInstance.updateSettings({
        columns: self.columnsHt,
        colHeaders: self.colHeadersHt,
        colWidths: self.colWidthsHt,
      })
    },
    computedReadOnly(v) {
      let self = this;
      self.hotInstance.updateSettings({
        readOnly: v
      });
    }
  },
  methods: {
    fitData(data) {
      let self = this;
      let h = self.hotInstance;
      h.cachedItems = h.cachedItems || {};
      data.map(function (fila) {
        // Objetivo: Handsontable no admite objetos en una celda, pero sí en los datos si las celdas apuntan a una propiedad del objeto. Si alguna propiedad es un objeto y tiene una columna asociada, se va a tratar con m2o: lo cacheamos y asignamos el ID
        // Si no tiene ninguna celda asociada, se mantiene el objeto para que pueda accederse a sus propiedades en celdas
        // Todo este bloque se podría iterar
        for (let col in fila) {
          let c = self.columnsData.find((el) => el.name == col) || {};
          if (!c.name) continue;
          if (Array.isArray(fila[col]) && c.type == "files") {
            //files
            fila[col] = JSON.stringify(fila[col]);
          } else if (Array.isArray(fila[col])) {
            //m2m
            h.cachedItems[col] = h.cachedItems[col] || {};
            fila[col].forEach((item, idx) => {
              if (typeof item == "object") {
                h.cachedItems[col][item[c.primary || "id"]] = item;
                fila[col][idx] = item[c.primary || "id"];
              }
            });
            fila[col] = fila[col].join(",") || null;
          } else if (
            //m2o
            fila.hasOwnProperty(col) &&
            fila[col] &&
            typeof fila[col] == "object"
          ) {
            if (c.name) {
              h.cachedItems[col] = h.cachedItems[col] || {};
              h.cachedItems[col][fila[col][c.primary || "id"]] = fila[col];
              fila[col] = fila[col][c.primary || "id"];
            } else {
              for (let col2 in fila[col]) {
                let cc =
                  self.columnsData.find((el) => el.name == col + "." + col2) ||
                  {};
                if (
                  fila[col].hasOwnProperty(col2) &&
                  fila[col][col2] &&
                  typeof fila[col][col2] == "object" &&
                  cc.name
                ) {
                  h.cachedItems[col + "." + col2] =
                    h.cachedItems[col + "." + col2] || {};
                  h.cachedItems[col + "." + col2][
                    fila[col][col2][cc.primary || "id"]
                  ] = fila[col][col2];
                  fila[col][col2] = fila[col][col2][cc.primary || "id"];
                }
              }
            }
          }
        }
        return fila;
      });
    },
    cleanData(data, isHtData = true) {
      let self = this;
      if (isHtData) {
        let minRows = self.minRows == 0 ? 0 : self.minRows || 9;
        if (data.length <= minRows) {
          for (let r = data.length - 1; r >= 0; r--) {
            let l = data[r];
            if (!Object.keys(l).some(col => col != "_op" && l[col] != null)) {
              data.splice(r, 1);
            }
          }
        } else {
          data.splice(data.length - 1, 1)
        }
      } else {
        for (let r = data.length - 1; r >= 0; r--) {
          let l = data[r];
          if (!Object.keys(l).some(col => col != "_op" && l[col] != null)) {
            data.splice(r, 1);
          }
        }
      }
    },
    afterRenderer: function (td, row, column, prop, value, cellProperties) {
      var self = this;
      if (value) {
        td.setAttribute("title", value);
      }
      if (cellProperties.readOnly && !td.style.background) {
        //td.style.background = "#f2f2f2";
        td.classList.add("bg-gray");
      }
      var val = self.hotInstance.getSourceData();
      if (Object.keys(val[row]).length)
        if (parseInt(val[row].wt)) {
          td.classList.add("bg-wt");
        }
      if (self.form.errors[self.name]) {
        let errores = (self.getContext(false, true) || {})[self.getName()];
        if (errores[row] && errores[row][prop]) {
          td.classList.add("wrong");
          td.setAttribute("title", errores[row][prop]);
        } else {
          td.classList.remove("wrong");
          td.removeAttribute("title");
        }
      }
      if (cellProperties?.beforeRenderer) {
        //window.console.log(td);
        cellProperties.beforeRenderer(td, value);
      }
    },
    consolelog(a, b, c) {
      window.console.log(a, b, c);
    },

    afterRemoveRow(index, amount, physicalRows, source) {
      window.console.log("afterRemoveRow", physicalRows);
    },
    removeError: function () {
      // Override: desactivar
    },
    getValue() {
      var self = this;
      let data = this.getContext(true)[this.getName()] || [];
      return data;
    },
    /*setValue: function(val) {
      var self = this;
      self.$set(self.form.formData, self.name, val);
    },*/
    restoreTableStructure() {
      var self = this;
      self.$set(self, "columnsData", self.$utils.misc.clone(self.columns));
      window.DB.action("table_structure", "restoreStructureField", {
        data: {
          id: self.structureRef
            ? self.structureRef
            : self.form.dbAdapter + "." + self.name,
        },
        method: "POST",
      })
        .then(function () {
          self.app.toast("Estructura restaurada con éxito");
        })
        .catch(function (e) {
          self.app.toast(e);
        });
    },
    saveTableStructure() {
      var self = this;
      var h = self.hotInstance;
      var widths = Array();
      var cols = Array();
      for (let cd of self.columnsData) {
        cols.push(cd.name);
        widths.push(cd.width || null);
      }
      window.DB.action("table_structure", "saveStructureField", {
        data: {
          id: self.structureRef
            ? self.structureRef
            : self.form.dbAdapter + "." + self.name,
          columns: cols,
          colWidths: widths,
        },
        method: "POST",
      })
        .then(function () {
          self.app.toast("Estructura almacenada con éxito");
        })
        .catch(function (e) {
          self.app.toast(e);
        });
    },
    refreshTableStructure: function () {
      var self = this;
      var h = self.hotInstance;
      return window.DB.action("table_structure", "getStructureField", {
        data: {
          id: self.structureRef
            ? self.structureRef
            : self.form.dbAdapter + "." + self.name,
        },
      })
        .then(function (res) {
          //res.columns
          res = res.data;
          var cols = [];
          var copy_columns = self.$utils.misc.clone(self.columns);
          for (let i in res.columns) {
            let c = res.columns[i];
            let cd = copy_columns.find((x) => x.name === c);
            if (!cd) continue;
            (cd.width = res.colWidths[i] || 100), cols.push(cd);
          }
          self.columnsData = cols;
        })
        .catch(function (e) {
          self.columnsData = self.$utils.misc.clone(self.columns);
          //window.console.log(e);
        });
    },
    beforeColumnSort: function (
      currentSortConfig,
      destinationSortConfigs
    ) {
      let self = this;
      let h = self.hotInstance;
      h.deselectCell();
      let data = this.getContext(true)[this.getName()];


      let order = h.colToProp(destinationSortConfigs[0].column);
      self.orderDir = self.orderDir == "ASC" ? "DESC" : "ASC";

      data.sort((a, b) => (a[order] > b[order] ? 1 : (a[order] < b[order] ? -1 : 0)) * (self.orderDir == 'ASC' ? 1 : -1));
      if (self.primary == 'id') data.forEach(v => delete v[self.primary]);

      self.cleanData(data, false);

      self.setValue(data.length ? [...data] : false);
      setTimeout(() => window.console.log(self), 500);
      self.onChange();
      return false;
    },
    bindFields() {
      var self = this;
      if (self.noBindField) return;
      self.form.fields.push({
        name: self.name,
        fields: [
          ...self.columns.map(function (c) {
            switch (c.type) {
              case "m2o":
              case "m2m":
                var fields = [...[c.label || "nombre"], ...(c.fields || [])];
                if (c.image) {
                  let ar = c.image.split(".");
                  let ctx = fields;
                  for (let i = 0; i < ar.length; i++) {
                    if (i == ar.length - 1) {
                      ctx.push(ar[i]);
                    } else {
                      ctx.push({
                        name: ar[i],
                        fields: [],
                      });
                      ctx = ctx[ctx.length - 1].fields;
                    }
                  }
                }
                return { name: c.name, fields: fields };
              default:
                return c.name;
            }
          }),
          ...self.fields,
        ],
      });
    },
  },
  mounted: function () {
    var self = this;
    let hot = self.$refs.hotTableComponent;

    self.hotInstance = new Handsontable(hot, {
      ...{
        licenseKey: 'non-commercial-and-evaluation',
        //autoRowSize: { syncLimit: 300 },
        dataSchema: {},
        multiSelect: true,
        rowHeaders: true,
        width: self.width,
        height: self.height,
        columnSorting: true,
        sortIndicator: true,
        manualColumnResize: true,
        manualColumnMove: true,
        fillHandle: {
          direction: 'vertical',
          autoInsertRow: true,
        },
        readOnly: self.computedReadOnly,
        columns: self.columnsHt,
        colHeaders: self.colHeadersHt,
        colWidths: self.colWidthsHt,
        data: self.getValue(),
        invalidCellClassName: 'wrong-cell', //TODO
        minRows: self.minRows == 0 ? 0 : self.minRows || 9,
        minSpareRows: 1,
        afterChange: self.afterChange,
        rowHeights: 20,
        afterRenderer: self.afterRenderer,
        observeChanges: false,
        enterBeginsEditing: false,
        //enterMoves: {row: 0, col: 1},
        autoWrapCol: false,
        autoWrapRow: true,
        rowHeaderWidth: 30,
        /*beforeKeyDown: function(event) {
        var h = this;
        var sel = h.getSelected();
        var keyCode = event.keyCode || event.which;
        if (
          keyCode == 40 &&
          typeof sel != 'undefined' &&
          sel.length &&
          h.isEmptyRow(sel[0])
        ) {
          event.stopImmediatePropagation();
        }
      },*/
        beforeOnCellMouseDown(event, coords, element, d) {
          var h = this;
          var sel = h.getSelected();
          if (
            typeof sel != 'undefined' &&
            sel.length &&
            h.isEmptyRow(sel[0]) &&
            sel[0] < coords.row
          ) {
            event.stopImmediatePropagation();
          }
        },
        afterSelection(r, c, r2, c2) {
          var h = this;
          if (h.isEmptyRow(0) && r != 0) {
            h.selectCell(0, c);
          } else if (h.isEmptyRow(r - 1) && r > 0) {
            h.selectCell(r - 1, c);
          }
        },
        beforeColumnMove(cls, target) {
          let colStart = cls[0];
          let nCols = cls.length;
          self.columnsData.splice(
            target > colStart ? target - nCols : target,
            0,
            ...self.columnsData.splice(colStart, nCols)
          );
          return false;
        },
        afterColumnResize(c, w) {
          this.getPlugin('ManualColumnResize').clearManualSize(c);
          //this.render();
          self.$set(self.columnsData[c], 'width', w);
        },
        beforePaste: (data, coords) => {
          for (let row in data) {
            if (data[row].length > self.columnsData.length) {
              data[row].length = self.columnsData.length;
            }
          }
        },
        contextMenu: self.contextMenu,
        beforeColumnSort: self.beforeColumnSort,
      },
      ...self.htSettings,
    });

    self.refreshTableStructure().finally(_ => self.loadedStructure = true);

    self.$el.addEventListener("keydown", function (e) {
      if (self.$el === document.activeElement) {
        e.preventDefault();
        if (e.key == " ") {
          var h = self.hotInstance;
          h.selectCell(0, 0);
        }
        if (e.key == "Enter") {
          if (e.shiftKey) self.focusPrevField(e.target);
          else self.focusNextField(self.$el);
        }
      } else if (e.key == "Tab") {
        e.preventDefault();
        /*e.preventDefault();
        e.stopPropagation();*/
        e.noCambiarTab = true;
      } else if (e.keyCode == 27) {
        e.noAbrirAcordeon = true;
      }
    });
  },
};
</script>
<style>
.handsontable th,
.handsontable td,
.handsontableInput {
  height: 20px !important;
  line-height: 20px !important;
  font-size: 12px;
  background-color: white;
}

.autocomplete2-code {
  background: #eee;
  padding: 0 3px;
  min-width: 2em;
}

.autocomplete2-image {
  vertical-align: top;
  height: 20px;
  margin-left: auto;
  margin: 0;
  padding: 0;
}

.autocomplete2-image:hover {
  height: 100px;
  margin-top: -10px;
  position: absolute;
  box-shadow: 5px 5px 5px #ddd;
  z-index: 9999999999;
}

.autocompleteEditor,
.autocompleteEditor>.ht_master,
.autocompleteEditor>.ht_master>.wtHolder {
  overflow: visible !important;
}

.handsontable input:focus,
.handsontable textarea:focus {
  background-color: var(--color-primary-light);
}

.autocomplete-box {
  display: inline-block;
  padding: 0 2px;
}

.htContextMenu table tbody tr td.htSeparator {
  height: 0px !important;
}

/* th width 100% */
/*.handsontable .colHeader {
  width: 100%;
}*/

/*.handsontable .ht_master .wtHolder {
  scroll-snap-type: x mandatory;
  scroll-padding: 50%;
}
.handsontable .ht_master .wtHolder th {
  scroll-snap-align: center;
}*/
.hotTableComponent.readonly td {
  background: #f2f2f2;
}
</style>
