<template>
  <div class="image-container" :class="{wrong: name in form.errors}" v-tooltip="form.errors[name]">
    <img style="border: 1px solid #ccc" width="100%" :src="getValue()[imgProp] || require('./../assets/noimage.png')" />
    <template
      v-if="!(readonly||form.mode=='view'||form.mode=='empty'||(form.mode=='search'&&!searchable))"
    >
      <span class="m2o_img-backdropEdit" @click="openGallery">
        <fa-icon icon="images" size="1x" style="color:#888;" />
      </span>
      <div
        class="m2o_img-removeButton"
        @click="setValue(null); onChange()"
        :style="closeButtonStyle"
      >
        <fa-icon icon="trash" size="1x" style="color:#aaa;" />
      </div>
    </template>
    <input
      ref="input"
      :value="getValue()[primary] || null"
      type="text"
      class="m2o_img-input"
      :placeholder="placeholder"
      :style="inputStyle"
      @change="onChangeInput"
      :readonly="readonly||form.mode=='view'||form.mode=='empty'||(form.mode=='search'&&!searchable)"
    />
  </div>
</template>
<script>
import FieldMixin from "./FieldMixin.vue";
export default {
  inject: ["rformc", "app"],
  mixins: [FieldMixin],
  props: {
    /*rform: {},
    name: { type: String },
    width: { type: String },
    required: { type: Boolean },
    readonly: { type: Boolean },
    searchable: { type: Boolean, default: false },
    help: {}*/

    placeholder: { type: String },
    inputStyle: { type: String },
    closeButtonStyle: { type: String },

    primary: { type: String, default: "codigo" },
    imgProp: { type: String, default: "imagen" },
    //searchProp: { type: String, default: "nombre" },
    dbAdapter: {},
    fields: { type: Array, default: () => [] },
    gallery: { type: String, default: "galeria_articulo" }
  },
  computed: {
    classMain: function() {
      var classMain = {};
      classMain["inline-label"] = this.inlineLabel;
      classMain["readonly"] = this.readonly || this.form.readonly;
      classMain["mode-" + this.form.mode] = true;
      return classMain;
    }
  },
  data: function() {
    var self = this;
    return {
      isLoading: false,
      form: this.rform ? this.rform : this.rformc
    };
  },
  methods: {
    openGallery() {
      var self = this;
      this.app.openChildWindow(this.gallery, this.form.win, {
        backdrop: true,
        selectable: true,
        onSelect: function(that) {
          self.setValue(that.formData);
          self.onChange();
          var winParentList = self.form.win.children;
          winParentList.splice(winParentList.indexOf(that.win), 1);
        }
      });
    },
    onChangeInput(e) {
      var self = this;
      self.isLoading = true;
      let fields = [self.imgProp.split(".")[0]];
      fields = [...fields, ...self.fields];
      let params = {
        itemId: e.target.value,
        fields: fields
      };
      if (!self.dbAdapter) params.field = self.name;
      window.DB.get(self.dbAdapter || self.form.dbAdapter, params)
        .then(function(data) {
          self.setValue(data);
          self.onChange();
        })
        .catch(function(e) {
          window.console.log(e);
          self.app.toast(e.msg || e, "error");
          self.setValue(null);
          self.onChange();
        })
        .finally(function() {
          self.isLoading = false;
          self.$forceUpdate();
        });
    },
    getValue: function() {
      return this.getContext()[this.getName()] || {};
    },
    /*setValue: function(val) {
      var self = this;
      self.$set(self.form.formData, self.name, val);
    },
    getValue: function() {
      var self = this;
      var val = self.form.formData[self.name];
      if (
        !val ||
        typeof val != "object" ||
        !val[self.primary] ||
        !val[self.imgProp]
      )
        return null;
      return val || null;
    }
    setValue: function(val) {
      var self = this;
      self.$set(self.form.formData, self.name, val);
    },*/
    bindFields() {
      var self = this;
      let fields = [self.imgProp.split(".")[0]];
      fields = [...fields, ...self.fields];
      self.form.fields.push({
        name: self.name,
        fields
      });
      self.form.filterConfig[self.name] = {
        searchField: self.primary,
        operation: "="
      };
    }
  },
  mounted: function() {
    //window.console.log(this.form);
  }
};
</script>
<style>
.m2o_img-removeButton {
  position: absolute;
  left: 70px;
  top: 100%;
  color: #666;
  cursor: pointer;
}

input.m2o_img-input {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 65px;
  text-align: center;
}

.m2o_img-backdropEdit {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
}
.m2o_img-backdropEdit:hover {
  opacity: 1;
}
</style>
