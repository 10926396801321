<template>
  <div class="window-wrap">
    <component
      class="window"
      :class="{'window-active': winidx==winParentList.length-1,'window-minimized': win.minimized}"
      :is="win.component"
      :win="win"
      :winParentList="winParentList"
      v-bind="win.opts&&win.opts.props"
      :winidx="winidx"
      :winParent="winParent"
      :winOptions="win.opts"
      :minimized="win.minimized"
      :resizable="win.opts&&win.opts.resizable||false"
      @minimize="winParentList.unshift(winParentList[winidx]); winParentList.splice(winidx+1, 1); win.minimized = true; "
      @close="winParentList.splice(winidx,1)"
      @focus="$emit('focus'); renderTables(); winParentList.push(winParentList[winidx]); winParentList.splice(winidx, 1);"
    ></component>
    <template v-for="(child,ic) in win.children">
      <div
        v-if="ic == (win.children.length-1) && child.opts && child.opts.backdrop"
        class="backdrop"
        :key="'backdrop-' + child.id"
      ></div>
      <window
        :win="child"
        :key="'window-'+child.id"
        ref="windows"
        :winParentList="win.children"
        :winidx="ic"
        :winParent="win"
        @focus="$emit('focus'); winParentList.push(winParentList[winidx]); winParentList.splice(winidx, 1);"
      ></window>
    </template>
  </div>
</template>
<script>
export default {
  provide: function() {
    return { win: this.win };
  },
  mixins: [],
  name: "window",
  props: {
    win: Object,
    winParent: Object,
    winParentList: Array,
    winidx: Number
  },
  methods: {
    renderTables() {
      var self = this;
      if (this.winParentList[this.winParentList.length - 1] === this.win)
        return;
      let iterateChildren = function(comp) {
        comp.$children.forEach(function(c) {
          if (c && c.hotInstance)
            setTimeout(function() {
              c.hotInstance.render();
            }, 50);
          iterateChildren(c);
        });
      };
      iterateChildren(this.win.instance);
      /*window
        .$(this.win.instance.$el)
        .find('.field[widget="handsontable"]')
        .each(function(i, $h) {
          window.console.log($h.field.hotInstance);
        });*/
    }
  }
};
</script>
<style>
.window-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
}
</style>
