<template>
  <div class="login">
    <h1 class="title">ERP</h1>
    <form action class="form" @submit.prevent="login" v-if="dbs">
      <label class="form-label" for="#database">
        Base de datos
        <router-link to="/login-db-management" style="float:right;">> Gestionar</router-link>
      </label>
      <select @change="db_selected = $event.target.value">
        <option
          v-for="(db,name) in dbs"
          :key="name"
          :selected="name==db_selected"
          :value="name"
        >{{db.nombre_comercial}} ({{name}})</option>
      </select>
      <label class="form-label" for="#username">Usuario</label>
      <input class="form-input" ref="username" id="username" required placeholder="Usuario" />
      <label class="form-label" for="#password">Contraseña</label>
      <input
        class="form-input"
        ref="password"
        type="password"
        id="password"
        placeholder="Contraseña"
      />
      <template v-if="parseInt(dbs[db_selected].tarjeta_coordenadas_activa)">
        <label class="form-label" for="#celda">Tarjeta de coordenadas {{letra}}{{numero}}</label>
        <input
          class="form-input"
          ref="celda"
          id="celda"
          required
          placeholder="Tarjeta de coordenadas"
        />
      </template>
      <input class="form-submit" type="submit" value="Entrar" />
      <div class="form-error">{{error}}</div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: "",
      empresa: {},
      db_selected: null,
      dbs: null,
      letra: null,
      numero: null
    };
  },
  methods: {
    login: function() {
      var self = this;
      let data = {};
      data.login = self.$refs.username.value;
      data.password = self.$refs.password.value;
      data.db_name = self.db_selected;
      if (parseInt(self.dbs[self.db_selected].tarjeta_coordenadas_activa)) {
        data.letra = self.letra;
        data.numero = self.numero;
        data.celda = self.$refs.celda.value;
      }

      window.DB.action("usuario", "login", {
        data,
        method: "POST"
      })
        .then(res => {
          localStorage.setItem(
            "session",
            JSON.stringify({ token: res.token, uid: res.uid, mode: res.mode })
          );
          localStorage.setItem("db_name", self.db_selected);
          self.$router.push("/");
        })
        .catch(res => (self.error = res.msg));
    }
  },
  mounted() {
    var self = this;

    // Cargar empresas
    window.DB.getDatabases().then(function(dbs) {
      self.dbs = dbs;
      if (!Object.keys(dbs).length) return;
      // Cargar última base de datos seleccionada
      let db_name = localStorage.getItem("db_name");
      if (db_name in dbs) {
        self.db_selected = db_name;
      } else {
        self.db_selected = Object.keys(dbs)[0];
      }
    });

    // Random tarjeta
    let letras = ["A", "B", "C", "D", "E", "F", "G", "H"];
    let numeros = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    self.letra = letras[Math.floor(Math.random() * letras.length)];
    self.numero = numeros[Math.floor(Math.random() * numeros.length)];
  }
};
</script>
<style>
body {
  background: #eee;
}
</style>
<style scoped>
a {
  color: white;
}
.login {
  padding: 2rem;
}
.title {
  text-align: center;
}
.form {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  min-width: 350px;
  max-width: 100%;
  background: rgba(19, 35, 47, 0.9);
  border-radius: 5px;
  padding: 40px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
}
.form-label {
  margin-top: 2rem;
  color: white;
  margin-bottom: 0.5rem;
}
.form-label:first-of-type {
  margin-top: 0rem;
}
.form-input {
  padding: 10px 10px;
  background: none;
  background-image: none;
  border: 1px solid white;
  color: white;
}
.form-input:focus {
  outline: 0;
  border-color: #1ab188;
}
.form-submit {
  background: #1ab188;
  border: none;
  color: white;
  margin-top: 3rem;
  padding: 1rem 0;
  cursor: pointer;
  transition: background 0.2s;
  height: 3rem;
}
.form-submit:hover {
  background: #0b9185;
}

.form-error {
  color: red;
  padding: 1rem;
}
</style>