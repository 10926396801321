<template>
  <div class="field item-input" :name="name" :class="classMain">
    <slot name="media">
      <div class="item-media" v-if="image">
        <img v-if="image" :src="image" style="height: 36px" />
      </div>
    </slot>
    <div class="item-inner">
      <label v-if="label" v-tooltip="help" class="item-title item-label" :for="name" v-html="label"></label>
      <div class="item-input-wrap">
        <input ref="input" :value="getValue()" @input="type != 'number' ? setValue($event.target.value) : null"
          autocomplete="off" :type="type == 'number' ? 'text' : type" v-on="$listeners" :placeholder="placeholder"
          :maxlength="maxlength" :style="'width:' + width + ';' + inputStyle" :readonly="
            (readonly && form.mode != 'search') ||
            form.mode == 'view' ||
            form.mode == 'empty' ||
            (form.mode == 'search' && !searchable)
          " v-tooltip="form.errors[name]" :name="name" @change="onChange"
          :class="{ 'text-align-right': type == 'number' }" />
      </div>
    </div>
  </div>
</template>
<script>
import FieldMixin from "./FieldMixin.vue";
export default {
  inject: ["rformc"],
  mixins: [FieldMixin],
  props: {
    /*rform: {},
    name: { type: String },
    width: { type: String },
    required: { type: Boolean },
    readonly: { type: Boolean },
    searchable: { type: Boolean, default: false },
    help: {},*/
    label: { type: String },
    "inline-label": { type: Boolean },
    maxlength: { type: Number },
    placeholder: { type: String },
    inputStyle: { type: String },
    image: { type: String },
    dec: { default: 2 },
    type: { type: String, default: "text" },
    suffix: { type: String },
  },
  computed: {
    classMain: function () {
      var classMain = {};
      classMain["inline-label"] = this.inlineLabel;
      classMain["readonly"] = this.readonly || this.form.readonly;
      classMain["mode-" + this.form.mode] = true;
      classMain["wrong"] = this.name in this.form.errors;
      classMain["searchable"] = this.searchable;
      classMain["autofocus"] = this.autofocus;
      return classMain;
    },
  },
  data: function () {
    return {
      form: this.rform ? this.rform : this.rformc,
    };
  },
  methods: {
    setValue: function (val) {
      this.$set(this.getContext(true), this.getName(), val);
    },
    onChange: function (e) {
      var self = this;
      let val = e.target.value;
      if (self.type == "number") {
        /*val = val.replace(/[^\d.-]/g, "").replace(",", ".");
        val = (parseFloat(val) || 0).toFixed(self.dec);*/
        val = parseFloat(val.replace(/[,]+/g, ".").replace(/[^0-9.]+/g, "")).toFixedNumber(self.dec);
        val = isFinite(val) ? val : null;
        self.setValue(val);
      }
      self.removeError();
      if (
        self.searchable &&
        self.form.mode == "search" &&
        self.form.htSettings
      ) {
        self.$nextTick(() => self.form.setFilter(self.form.getFilter()));
      }
    },
    getValue: function () {
      let val = this.getContext()[this.getName()];
      if (this.type == "number" && isFinite(val) && val != null) {
        val = this.$utils.misc.formatNumber(val, this.dec, this.suffix);
      }
      return val;
    },
  },
  mounted: function () {
    var self = this;
  },
};
</script>
<style>
/* rInput */
.field {
  position: absolute;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.field label {
  color: #aaa;
  font-size: 11px;
}

.item-input .item-inner {
  display: flex;
  flex-direction: column;
  align-items: flºex-start;
}

.inline-label.item-input .item-inner {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.item-media {
  margin: 3px;
}

.inline-label .item-label+.item-input-wrap {
  margin-left: 8px;
  display: flex;
  /*inline;*/
}

input {
  padding: 1px 3px;
  height: 1.5em;
  line-height: 1.5em;
  border-radius: 3px;
  /*border: none !important;
    border-bottom: solid 1px #CCC !important;*/
  border: 1px solid #ccc;
  position: relative;
  font-family: -apple-system, system-ui, Helvetica, Arial, sans-serif;
}

.field.mode-search.searchable input {
  background-color: #ffffee;
}

.field input:read-only {
  /*border-color: transparent;*/
  background-color: #eee !important;
  /*border-bottom: 1px solid #ddd;*/
}

.field input:not([type="checkbox"]):focus {
  background-color: rgba(0, 140, 186, 0.1);
}

.item-input-wrap {
  position: relative;
  display: flex;
}

.required:not(.wrong) .item-input-wrap::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
  height: 1px;
  background: #4b96e0 !important;
}

.wrong input {
  /*border: solid 1px #ff6a6a;*/
  background: var(--color-wrong);
}

.field input[type="number"] {
  text-align: right;
}

.text-align-right {
  text-align: right;
}
</style>