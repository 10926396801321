import { checkVAT, countries } from 'jsvat';
export default {
    checkVATNumber: function (value) {
        if (value.length < 2) return false;
        if (isFinite(value[0]) || isFinite(value[1])) {
            value = 'ES' + value;
        }
        if (value.startsWith('ES') && value.length < 11) value = 'ES' + (value.substring(2).padStart(9, '0'));
        return checkVAT(value, countries).isValid;
    },
    checkEmail: function (value) {
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        return (filter.test(value));
    },
    'checkNif': function (value) {
        value = value.toUpperCase();
        // Basic format test
        if (!value.match('((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)')) {
            return false;
        }
        // Test NIF
        if (/^[0-9]{8}[A-Z]{1}$/.test(value)) {
            return ("TRWAGMYFPDXBNJZSQVHLCKE".charAt(value.substring(8, 0) % 23) === value.charAt(8));
        }
        // Test specials NIF (starts with K, L or M)
        if (/^[KLM]{1}/.test(value)) {
            return (value[8] === String.fromCharCode(64));
        }
        return false;
    },
    'checkNie': function (value) {
        value = value.toUpperCase();

        // Basic format test
        if (!value.match('((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)')) {
            return false;
        }
        // Test NIE
        //T
        if (/^[T]{1}/.test(value)) {
            return (value[8] === /^[T]{1}[A-Z0-9]{8}$/.test(value));
        }
        //XYZ
        if (/^[XYZ]{1}/.test(value)) {
            return (
                value[8] === "TRWAGMYFPDXBNJZSQVHLCKE".charAt(
                    value.replace('X', '0')
                        .replace('Y', '1')
                        .replace('Z', '2')
                        .substring(0, 8) % 23
                )
            );
        }
        return false;
    },
    'checkCif': function (value) {
        var sum,
            num = [],
            controlDigit;
        value = value.toUpperCase();
        // Basic format test
        if (!value.match('((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)')) {
            return false;
        }
        for (var i = 0; i < 9; i++) {
            num[i] = parseInt(value.charAt(i), 10);
        }
        // Algorithm for checking CIF codes
        sum = num[2] + num[4] + num[6];
        for (var count = 1; count < 8; count += 2) {
            var tmp = (2 * num[count]).toString(),
                secondDigit = tmp.charAt(1);

            sum += parseInt(tmp.charAt(0), 10) + (secondDigit === '' ? 0 : parseInt(secondDigit, 10));
        }
        // CIF test
        if (/^[ABCDEFGHJNPQRSUVW]{1}/.test(value)) {
            sum += '';
            controlDigit = 10 - parseInt(sum.charAt(sum.length - 1), 10);
            value += controlDigit;
            return (num[8].toString() === String.fromCharCode(64 + controlDigit) || num[8].toString() === value.charAt(value.length - 1));
        }
        return false;
    },
    'checkCCC': function (entidad, sucursal, dc, nCuenta) {
        function obtenerDigito(valor) {
            var valores = new Array(1, 2, 4, 8, 5, 10, 9, 7, 3, 6);
            var control = 0;
            for (let i = 0; i <= 9; i++)
                control += parseInt(valor.charAt(i)) * valores[i];
            control = 11 - (control % 11);
            if (control == 11) control = 0;
            else if (control == 10) control = 1;
            return control;
        }
        if (!(obtenerDigito("00" + entidad + sucursal) ==
            parseInt(dc.charAt(0))) ||
            !(obtenerDigito(nCuenta) ==
                parseInt(dc.charAt(1)))) {
            //alert ("El número de cuenta no es correcto");
            return false;
        } else {
            //alert ("CCC correcto");
            return true;
        }
    },
    'calculateIBAN': function (ncuenta, cpais) {
        if (cpais.length != 2)
            return "";
        else {
            var aux;
            var csiguientes;
            var tmp;
            ncuenta = ncuenta + (cpais.charCodeAt(0) - 55).toString() + (cpais.charCodeAt(1) - 55).toString() + "00";
            //Hay que calcular el módulo 97 del valor contenido en número de cuenta
            //Como el número es muy grande vamos calculando módulos 97 de 9 en 9 dígitos
            //Lo que se hace es calcular el módulo 97 y al resto se le añaden 7 u 8 dígitos en función de que el resto sea de 1 ó 2 dígitos
            //Y así sucesivamente hasta tratar todos los dígitos
            tmp = parseInt(ncuenta.substring(0, 9), 10) % 97;
            if (tmp < 10)
                aux = "0";
            else
                aux = "";
            aux = aux + tmp.toString();
            ncuenta = ncuenta.substring(9);
            while (ncuenta != "") {
                if (parseInt(aux, 10) < 10)
                    csiguientes = 8;
                else
                    csiguientes = 7;
                if (ncuenta.length < csiguientes) {
                    aux = aux + ncuenta;
                    ncuenta = "";
                } else {
                    aux = aux + ncuenta.substring(0, csiguientes);
                    ncuenta = ncuenta.substring(csiguientes);
                }
                tmp = parseInt(aux, 10) % 97;
                if (tmp < 10)
                    aux = "0";
                else
                    aux = "";
                aux = aux + tmp.toString();
            }
            tmp = 98 - parseInt(aux, 10);
            if (tmp < 10)
                return cpais + "0" + tmp.toString();
            else
                return cpais + tmp.toString();
        }
    }
};