<template>
  <nav id="top-menu">
    <div class="right">
      <a
        href="#"
        v-tooltip="$t('global.permitir_movilidad_widgets')"
        class="button button-plain"
        @click="app.editarWidgets = !app.editarWidgets; app.guardarWidgets()"
      >
        <fa-icon
          icon="arrows-alt"
          :style="{ color: app.editarWidgets ? 'var(--color-primary)' : null }"
        />
      </a>
      <a
        href="#"
        v-tooltip="$t('global.pantalla_completa')"
        class="button button-plain"
        @click="document.documentElement.requestFullscreen()"
      >
        <fa-icon icon="expand-arrows-alt" />
      </a>
      <a
        href="#"
        v-tooltip="$t('global.minimizar_todo')"
        class="button button-plain"
        @click="
          app.windows.forEach(function (win) {
            win.minimized = true;
          })
        "
      >
        <fa-icon icon="minus-square" />
      </a>
      <a
        href="#"
        v-tooltip="$t('global.bloquear')"
        class="button button-plain"
        @click="app.bloqueado = true"
      >
        <fa-icon icon="unlock-alt" />
      </a>
      <a
        href="#"
        v-tooltip="$t('global.cerrar_sesion')"
        class="button button-plain"
        @click="app.logout()"
      >
        <fa-icon icon="power-off" />
      </a>
    </div>
  </nav>
</template>
<script>
export default {
  inject: ["app"],
  data: function () {
    return { document };
  },
};
</script>
<style scoped>
.svg-inline--fa {
  font-size: 1rem;
}
#top-menu {
  width: 100%;
  box-sizing: border-box;
  top: 0;
  padding: 10px;
  background: #f7f7f8;
  color: black;
  z-index: 9990;
  position: fixed;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0 var(--padding);
}

#top-menu a {
  margin: 0;
}
.right {
  margin-left: auto;
}
</style>