<template>
  <div class="field item-input" :class="classMain">
    <slot name="media">
      <div class="item-media">
        <img v-if="image" :src="image" style="height: 36px" />
      </div>
    </slot>
    <div class="item-inner" :class="{ reverse }">
      <label
        v-tooltip="help"
        class="item-title item-label"
        @click="$refs.input.click()"
        >{{ label }}</label
      >
      <div class="item-input-wrap">
        <input
          ref="input"
          autocomplete="new-password"
          type="checkbox"
          v-on="$listeners"
          :style="'width:' + width + ';' + inputStyle"
          :disabled="
            readonly ||
            form.mode == 'view' ||
            form.mode == 'empty' ||
            (form.mode == 'search' && !searchable)
          "
          v-tooltip="form.errors[name]"
          @input="setValue($event.target.checked ? 1 : 0)"
          :checked="getValue() ? true : false"
          @change="onChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import FieldMixin from "./FieldMixin.vue";
export default {
  inject: ["rformc"],
  mixins: [FieldMixin],
  props: {
    /*rform: {},
    name: { type: String },
    width: { type: String },
    required: { type: Boolean },
    readonly: { type: Boolean },
    searchable: { type: Boolean, default: false },
    help: {},*/
    label: { type: String },
    "inline-label": { type: Boolean },
    inputStyle: { type: String },
    image: { type: String },
    reverse: Boolean,
  },
  computed: {
    classMain: function () {
      var classMain = {};
      classMain["inline-label"] = this.inlineLabel;
      classMain["readonly"] = this.readonly || this.form.readonly;
      classMain["mode-" + this.form.mode] = true;
      classMain["wrong"] = this.name in this.form.errors;
      return classMain;
    },
  },
  data: function () {
    return {
      form: this.rform ? this.rform : this.rformc,
    };
  },
  methods: {
    getValue: function () {
      return parseInt(this.getContext()[this.getName()]);
    },
  },
  mounted: function () {
    //window.console.log(this.form);
  },
};
</script>
<style>
input[type="checkbox"] {
  margin: 0;
  vertical-align: middle;
}
.item-inner.reverse label {
  order: 2;
  margin-left: 5px;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
  line-height: 8px;
  padding: 0;
  text-align: center;
}
input[type="checkbox"]:checked {
  background: var(--color-primary)!important;
}
input[type="checkbox"]:before {
  content: "";
  font-size: 11px;
  line-height: 1;
  color: white;
}
input[type="checkbox"]:checked:before {
  content: "✓";
  font-weight: bold;
}
</style>
