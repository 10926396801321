/// files plugin
import Handsontable from "handsontable";
import jQuery from "jquery";
(function (Handsontable, jQuery) {
	"use strict";

	var $ = jQuery;
	var $temp = $('<span class="temp"> \
		<img src="'+ require("./../../assets/attachment-icon-plus.png") + '"> \
		<input type="file" accept=".pdf,.doc,.docx,.xls,.xlsx"/> \
	</span>');

	var FilesEditor = Handsontable.editors.BaseEditor.prototype.extend();

	FilesEditor.prototype.prepare = function (row, col, prop, td, originalValue, cellProperties) {

		Handsontable.editors.BaseEditor.prototype.prepare.apply(this, arguments);
		var max = this.cellProperties.max || 3;

		this.options = {
			max: max
		};

		Handsontable.dom.empty(this.TEMPPARENT);
		var self = this;

		renderValueEditor(self, originalValue);
		appendtemplate(self);


	};

	/*FilesEditor.prototype.init = function () {
		Handsontable.editors.BaseEditor.prototype.init.apply(this, arguments);
	};*/

	FilesEditor.prototype.init = function () {
		this.$body = $(document.body);

		this.$temp = $temp;
		this.TEMP = this.$temp.get(0);
		//Handsontable.Dom.addClass(this.TEMP, 'handsontableInput');

		this.$tempparent = $('<div class="cellfiles" style="position:absolute; display:none;">');
		this.TEMPPARENT = this.$tempparent.get(0);
		//Handsontable.Dom.addClass(this.TEMPPARENT, 'handsontableInputHolder');

		this.instance.rootElement.appendChild(this.TEMPPARENT);


	};

	var updateValue = function (self) {
		var res = [];
		$.each(self.$tempparent.find('span:not(.temp)'), function (i, f) {
			res.push($(this).data('val'));
		});
		//console.log(res);
		self.$tempparent.data('val', JSON.stringify(res));
	}

	var renderValueEditor = function (self, value) {
		//var val = self.$tempparent.data('val');
		//console.log(value,val);

		if (typeof value == 'undefined' || !value || value == 'false') {
			self.$tempparent.html('');
			return;
		}
		if (typeof value == 'string') value = JSON.parse(value);


		$.each(value, function (i, f) {
			//console.log(f);
			var $n = self.$temp.clone().prependTo(self.$tempparent).removeClass('temp');
			$n.find('img').prop('src', require('./../../assets/attachment-icon.png'));
			$('<img class="close" src="' + require('./../../assets/close-circle.png') + '">').prependTo($n).click(function () {
				$(this).parent().remove();
				updateValue(self);
				appendtemplate(self);
			});
			$n.data('val', f);
			//updateValue(self);
			$n.prop('title', f.nombre);
			var $imm = $n.find('img:not(.close)');
			var ext = (f.nombre || f.name || "").split(".").pop();
			switch (ext.toLowerCase()) {
				case "pdf":
					$imm.prop('src', require("./../../assets/pdf.png"));
					break;
				case "doc":
				case "docx":
					$imm.prop('src', require("./../../assets/doc.png"));
					break;
				case "xls":
				case "xlsx":
					$imm.prop('src', require("./../../assets/xls.png"));
					break;
				case "png":
				case "jpg":
				case "jpeg":
				case "gif":
					$imm.prop('src', f.file ? f.file : f.url);
					break;
			}
			/*if (f.type == 'application/pdf') {
				$imm.prop('src', require("./../../assets/pdf.png"));
			} else if (f.type.indexOf('wordprocessingml') > -1) {
				$imm.prop('src', require("./../../assets/doc.png"));
			} else if (f.type.indexOf('spreadsheetml') > -1) {
				$imm.prop('src', require("./../../assets/xls.png"));
			}*/
			$imm.click(function () {
				if (typeof f.url != 'undefined') {
					window.open(f.url, '_blank');
				} else {
					var win = window.open();
					win.document.write('<iframe src="' + f.file + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
				}
			});
		});
	}

	var appendtemplate = function (self) {
		var $tempparent = self.$tempparent;
		var max = self.options.max;

		if ($tempparent.find('span').length < max && $tempparent.find('.temp').length == 0) {
			//console.log($tempparent,$tempparent.find('.temp').length ,max);
			self.$temp.clone().appendTo($tempparent).find('img').click(function () {
				$(this).next('input').click();
			}).next('input').change(function (e) {
				additem($(this), e, self);
			});
		}
	};
	var additem = function ($if, e, self) {
		$if.prev('img').prop('src', require('./../../assets/attachment-icon.png')).off("click").parent().removeClass('temp');
		$('<img class="close" src="' + require('./../../assets/close-circle.png') + '">').prependTo($if.parent()).click(function () {
			$(this).parent().remove();
			appendtemplate(self);
		});
		appendtemplate(self);
		var f = e.target.files[0];
		f.nombre = f.name;
		window.console.log(f.nombre);
		$if.parent().prop('title', f.nombre);
		if (f.type == 'application/pdf') {
			$if.prev('img').prop('src', require("./../../assets/pdf.png"));
		} else if (f.type.indexOf('wordprocessingml') > -1) {
			$if.prev('img').prop('src', require("./../../assets/doc.png"));
		} else if (f.type.indexOf('spreadsheetml') > -1) {
			$if.prev('img').prop('src', require("./../../assets/xls.png"));
		}
		var reader = new FileReader();
		reader.onload = (function (theFile) {
			return function (e) {
				$if.parent().data('val', { nombre: f.name, name: f.name, size: f.size, type: f.type, file: e.target.result });
				updateValue(self);
				$if.prev('img').click(function () {
					var win = window.open();
					win.document.write('<iframe src="' + e.target.result + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
				});
			};
		})(f);
		reader.readAsDataURL(f);
	};


	FilesEditor.prototype.open = function (keyboardEvent) {
		var self = this;
		var width = Handsontable.dom.outerWidth(this.TD);
		var height = Handsontable.dom.outerHeight(this.TD);
		var rootOffset = Handsontable.dom.offset(this.instance.rootElement);
		var tdOffset = Handsontable.dom.offset(this.TD);

		this.$tempparent.css({
			top: tdOffset.top - rootOffset.top + 2 + 'px',
			left: tdOffset.left - rootOffset.left + 5 + 'px',
			margin: '0px',
			padding: '0px',
			height: '20px',
			width: width + 'px'
		});
		this.$tempparent.show();
	};



	FilesEditor.prototype.close = function () {
		this.instance.listen();
		//this.instance.removeHook('beforeKeyDown', onBeforeKeyDown);
		this.$tempparent.off();
		this.$tempparent.hide();

		//Handsontable.editors.BaseEditor.prototype.close.apply(this, arguments);
	};

	/*FilesEditor.prototype.val = function (value) {
		if (typeof value == 'undefined') {
			return this.$textarea.val();
		} else {
			this.$textarea.val(value);
		}
	};*/
	FilesEditor.prototype.getValue = function () {
		/*var res=[];
		$.each(this.$tempparent.find('span:not(.temp)'), function( i, f ){
			res.push($(this).data('val'));
		});*/
		var res = this.$tempparent.data('val') || '';
		//return (res.length? res : false);
		return res;

	};
	FilesEditor.prototype.setValue = function (value) {
		var self = this;
		//console.log(typeof value);
		if (typeof value != 'string' && value) value = JSON.stringify(value);

		self.$tempparent.data('val', value);
		return;

	};

	FilesEditor.prototype.focus = function () {

		this.instance.listen();

		// DO NOT CALL THE BASE BaseEditor FOCUS METHOD HERE, IT CAN MAKE THIS EDITOR BEHAVE POORLY AND HAS NO PURPOSE WITHIN THE CONTEXT OF THIS EDITOR
		//Handsontable.editors.BaseEditor.prototype.focus.apply(this, arguments);
	};

	FilesEditor.prototype.beginEditing = function (initialValue) {
		var onBeginEditing = this.instance.getSettings().onBeginEditing;
		if (onBeginEditing && onBeginEditing() === false) {
			return;
		}

		Handsontable.editors.BaseEditor.prototype.beginEditing.apply(this, arguments);

	};

	FilesEditor.prototype.finishEditing = function (isCancelled, ctrlDown) {
		this.instance.listen();
		return Handsontable.editors.BaseEditor.prototype.finishEditing.apply(this, arguments);
	};

	Handsontable.editors.FilesEditor = FilesEditor;
	Handsontable.editors.registerEditor('files', FilesEditor);

	/* RENDERER */

	var FilesRenderer = function (instance, td, row, column, prop, value, cellProperties) {
		let c = cellProperties;

		var $ = window.$;


		if (typeof value == 'undefined' || !value || value == 'false') {
			td.innerHTML = '';
			return td;
		}
		if (typeof value == 'string') value = JSON.parse(value);

		let $td = $(td).addClass('cellfiles').empty().css('display', 'block');
		$.each(value, function (i, f) {
			//console.log(f);
			var $n = $temp.clone().prependTo($td).removeClass('temp');
			$n.find('img').prop('src', require('./../../assets/attachment-icon.png'));
			$n.prop('title', f.nombre);
			var $imm = $n.find('img:not(.close)');
			var ext = (f.nombre || f.name || "").split(".").pop();
			switch (ext.toLowerCase()) {
				case "pdf":
					$imm.prop('src', require("./../../assets/pdf.png"));
					break;
				case "doc":
				case "docx":
					$imm.prop('src', require("./../../assets/doc.png"));
					break;
				case "xls":
				case "xlsx":
					$imm.prop('src', require("./../../assets/xls.png"));
					break;
				case "png":
				case "jpg":
				case "jpeg":
				case "gif":
					$imm.prop('src', f.file ? f.file : f.url);
					break;
			}
			/*if (f.type == 'application/pdf') {
				$imm.prop('src', require("./../../assets/pdf.png"));
			} else if (f.type.indexOf('wordprocessingml') > -1) {
				$imm.prop('src', require("./../../assets/doc.png"));
			} else if (f.type.indexOf('spreadsheetml') > -1) {
				$imm.prop('src', require("./../../assets/xls.png"));
			}*/
			$imm.click(function () {
				if (typeof f.url != 'undefined') {
					window.open(f.url, '_blank');
				} else {
					var win = window.open();
					win.document.write('<iframe src="' + f.file + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
				}
			});
		});
		return td;
	};

	Handsontable.renderers.FilesRenderer = FilesRenderer;
	Handsontable.renderers.registerRenderer('files', FilesRenderer);


	/* CELLTYPE */

	Handsontable.cellTypes.registerCellType('files', {
		editor: Handsontable.editors.FilesEditor,
		renderer: Handsontable.renderers.FilesRenderer
	});

})(Handsontable, jQuery);
