<template>
  <div class="suspend-window">
    <div class="login">
      <form action class="form" @submit.prevent="login">
        <label class="form-label" for="#password">Contraseña</label>
        <input
          class="form-input"
          ref="password"
          type="password"
          id="password"
          placeholder="Contraseña"
        />
        <input class="form-submit" type="submit" value="Entrar" />
        <div class="form-error">{{error}}</div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  inject: ["app"],
  data() {
    return { error: "" };
  },
  methods: {
    login: function() {
      var self = this;
      const username = self.app.usuario.login;
      const password = self.$refs.password.value;
      window.DB.action("usuario", "login", {
        data: { login: username, password },
        method: "POST"
      })
        .then(res => {
          localStorage.setItem(
            "session",
            JSON.stringify({ token: res.token, uid: res.uid })
          );
          self.app.bloqueado = false;
        })
        .catch(res => (self.error = res.msg));
    }
  }
};
</script>
<style>
.suspend-window {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999999;
  display: flex;
  align-items: middle;
  justify-content: center;
}
</style>
<style scoped>
.login {
  padding: 2rem;
}
.title {
  text-align: center;
}
.form {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  min-width: 350px;
  max-width: 100%;
  background: rgba(19, 35, 47, 0.9);
  border-radius: 5px;
  padding: 40px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
}
.form-label {
  margin-top: 2rem;
  color: white;
  margin-bottom: 0.5rem;
}
.form-label:first-of-type {
  margin-top: 0rem;
}
.form-input {
  padding: 10px 15px;
  background: none;
  background-image: none;
  border: 1px solid white;
  color: white;
}
.form-input:focus {
  outline: 0;
  border-color: #1ab188;
}
.form-submit {
  background: #1ab188;
  border: none;
  color: white;
  margin-top: 3rem;
  padding: 1rem 0;
  cursor: pointer;
  transition: background 0.2s;
  height: 3rem;
}
.form-submit:hover {
  background: #0b9185;
}

.form-error {
  color: red;
  padding: 1rem;
}
</style>