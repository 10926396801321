<template>
  <div class="main" :class="{loading}">
    <img class="loader" v-if="loading" :src="require('./assets/loading.gif')" />
    <h1 class="title">Administración de empresas</h1>

    <div style="max-width:800px;margin:auto;">
      <button
        class="button"
        style="margin:auto;display:block;margin:30px auto;"
        @click="popupOpened=true"
      >Crear nueva base de datos</button>

      <table width="100%">
        <thead>
          <tr>
            <th class="label-cell">BD</th>
            <th class="label-cell">CIF</th>
            <th class="label-cell">Nombre comercial</th>
            <th class="label-cell">Nombre fiscal</th>
            <th class="label-cell"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(db,db_name) in dbs" :key="'db-'+db_name">
            <td>{{db_name}}</td>
            <td>{{db.nif_cif_empresa}}</td>
            <td>{{db.nombre_comercial_empresa}}</td>
            <td>{{db.nombre_fiscal_empresa}}</td>
            <td style="text-align:center">
              <button type="button" class="button button-fill" @click="eliminar(db_name)">Eliminar</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div style="text-align:center;">
      <router-link to="/login" style="color:black; margin:30px auto;;display:inline-block">Salir</router-link>
      </div>
    </div>

    <form action class="form popup" @submit.prevent="createDatabase" v-if="popupOpened">
      <h2 style="color:white;">Crear nueva base de datos</h2>
      <label class="form-label" for="#cif">CIF</label>
      <input class="form-input" ref="cif" id="cif" placeholder="CIF" />
      <label class="form-label" for="#nombre_comercial">Nombre comercial</label>
      <input
        class="form-input"
        ref="nombre_comercial"
        id="nombre_comercial"
        placeholder="Nombre comercial"
      />
      <label class="form-label" for="#nombre_fiscal">Nombre fiscal</label>
      <input class="form-input" ref="nombre_fiscal" id="nombre_fiscal" placeholder="Nombre fiscal" />
      <label class="form-label" for="#db_name">Nombre de la base de datos</label>
      <input class="form-input" ref="db_name" id="db_name" placeholder="Base de datos" />
      <input class="form-submit" type="submit" value="Aceptar" />
      <div class="form-error">{{error}}</div>

      <a href="#" @click="popupOpened=false">Cancelar</a>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: "",
      dbs: {},
      popupOpened: false,
      loading: false
    };
  },
  methods: {
    loadDatabases() {
      var self = this;
      self.loading = true;
      window.DB.getDatabases()
        .then(function(dbs) {
          self.dbs = dbs;
        })
        .finally(function() {
          self.loading = false;
        });
    },
    createDatabase() {
      var self = this;
      let data = {};
      data.cif = self.$refs.cif.value;
      data.nombre_comercial = self.$refs.nombre_comercial.value;
      data.nombre_fiscal = self.$refs.nombre_fiscal.value;
      data.db_name = self.$refs.db_name.value;
      self.loading = true;
      window.DB.createDatabase(data)
        .then(function() {
          self.loadDatabases();
          self.popupOpened = false;
          window.alert("Base de datos creada con éxito");
        })
        .catch(function(res) {
          self.error = res.msg;
        })
        .finally(function() {
          self.loading = false;
        });
    },
    eliminar(db_name) {
      var self = this;
      if (window.confirm("¿Seguro que desea eliminar la base de datos?")) {
        self.loading = true;
        window.DB.deleteDatabase(db_name)
          .then(function() {
            window.alert("Base de datos eliminada con éxito");
            self.loadDatabases();
          })
          .finally(function() {
            self.loading = false;
          });
      }
    }
  },
  mounted() {
    var self = this;
    self.loadDatabases();
  }
};
</script>
<style>
body {
  background: #eee;
}
</style>
<style scoped>
a {
  color: white;
}
table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #1ab188;
  color: white;
}

.popup {
  position: absolute;
  left: 50%;
  top: 0px;
  margin-left: -160px !important;
}
.main {
  padding: 2rem;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.main.loading:after {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.loader {
  z-index: 999;
  width: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -35px;
  margin-top: -35px;
}
.title {
  text-align: center;
}
.form {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
  background: rgba(19, 35, 47, 1);
  border-radius: 5px;
  padding: 40px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
}
.form-label {
  margin-top: 2rem;
  color: white;
  margin-bottom: 0.5rem;
}
.form-label:first-of-type {
  margin-top: 0rem;
}
.form-input {
  padding: 10px 10px;
  background: none;
  background-image: none;
  border: 1px solid white;
  color: white;
}
.form-input:focus {
  outline: 0;
  border-color: #1ab188;
}
.form-submit {
  background: #1ab188;
  border: none;
  color: white;
  margin-top: 3rem;
  padding: 1rem 0;
  cursor: pointer;
  transition: background 0.2s;
  height: 3rem;
}
.form-submit:hover {
  background: #0b9185;
}

.form-error {
  color: red;
  padding: 1rem;
}
</style>