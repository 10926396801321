<template>
  <div class="field item-input" :name="name" :class="classMain" v-tooltip="form.errors[name]">
    <div class="item-inner">
      <label v-if="label" v-tooltip="help" class="item-title item-label" :for="name">{{label}}</label>
      <div class="item-input-wrap">
        <div class="buttonset" :class="{'inline-options':inlineOptions}">
          <label v-for="(io,opt) in options" :key="name+'-'+io" :style="'width:'+width+'px'">
            <input
              ref="input"
              type="radio"
              :name="name"
              :value="io"
              @input="setValue($event.target.value)"
              @change="onChange"
              :disabled="readonly||form.mode=='view'||form.mode=='empty'||(form.mode=='search'&&!searchable)"
            />
            <span
              class="button"
              :class="{active:getValue()==io, disabled: readonly||form.mode=='view'||form.mode=='empty'||(form.mode=='search'&&!searchable)}"
            >{{opt}}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FieldMixin from "./FieldMixin.vue";
export default {
  inject: ["rformc"],
  mixins: [FieldMixin],
  props: {
    /*rform: {},
    name: { type: String },
    width: { type: Number },
    required: { type: Boolean },
    readonly: { type: Boolean },
    searchable: { type: Boolean, default: false },
    help: {}*/
    label: { type: String },
    options: { type: Object },
    "inline-label": { type: Boolean },
    "inline-options": { type: Boolean, default: true },
    maxlength: { type: Number },
    placeholder: { type: String },
    inputStyle: { type: String }
  },
  computed: {
    classMain: function() {
      var classMain = {};
      classMain["inline-label"] = this.inlineLabel;
      classMain["readonly"] = this.readonly || this.form.readonly;
      classMain["mode-" + this.form.mode] = true;
      classMain["wrong"] = this.name in this.form.errors;
      return classMain;
    }
  },
  data: function() {
    return {
      form: this.rform ? this.rform : this.rformc
    };
  },
  methods: {
    /*getValue: function() {
      var self = this;
      return self.form.formData[self.name];
    },
    setValue: function(val) {
      var self = this;
      self.$set(self.form.formData, self.name, val);
    },
    onChange: function(e) {
      var self = this;
      self.setValue(e.target.value);
      if (self.form.mode == "search" && self.form.htSettings) {
        self.form.setFilter(self.form.getFilter());
      }
    }*/
  },
  mounted: function() {
    //window.console.log(this.form);
  }
};
</script>
<style>
.wrong .buttonset {
  /*border: solid 1px #ff6a6a;*/
  background: rgba(255, 106, 106, 0.2);
}

.buttonset {
  display: flex;
  flex-direction: column;
}
.buttonset.inline-options {
  flex-direction: row;
}
.buttonset input {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}
.buttonset label,
.buttonset .button {
  display: flex;
  margin: 0;
  border-radius: 0;
}
.buttonset label:hover,
.buttonset .button:hover {
  color: black;
}

.buttonset label:first-child,
.buttonset .button:first-child {
  border-radius: 3px 0 0 3px;
}
.buttonset label:last-child,
.buttonset .button:last-child {
  border-radius: 0 3px 3px 0;
}
.buttonset .button.active {
  background-color: var(--color-primary);
  color: white;
}
.buttonset .button.active:hover {
  background-color: var(--color-primary);
  color: white;
}
.readonly .buttonset {
  opacity: 0.5;
}

.buttonset input:focus + span {
  outline: 1px solid var(--color-primary);
  z-index: 9;
}

.field.wrong .buttonset .button {
  background: var(--color-wrong);
}
</style>
