<template>
  <div class="progressbar">
    <div class="progressbar-inner" :style="'width:'+Math.min(value||0, 100)+'%'">{{text}}</div>
  </div>
</template>
<script>
export default {
  props: {
    value: Number,
    text: String
  }
};
</script>
<style>
.progressbar {
  width: 100%;
  height: 1.5em;
  background: var(--color-primary-dark);
  border-radius: 3px;
}
.progressbar-inner {
  height: 100%;
  background: var(--color-primary);
  color: white;
  padding: 0 3px;
}
</style>
