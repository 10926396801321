<template>
  <component ref="field" class="field" :is="ComponentName" v-bind="{ ...$attrs, ...$props }" v-on="$listeners">
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
  </component>
</template>
<script>
import rInput from "./rInput.vue";
import rAutocomplete from "./rAutocomplete.vue";
import rToggle from "./rToggle.vue";
import rRadio from "./rRadio.vue";
import rHandsontable from "./rHandsontable.vue";
import rSelect from "./rSelect.vue";
import rCheckbox from "./rCheckbox.vue";
import rImage from "./rImage.vue";
import rM2oImage from "./rM2oImage.vue";
import rText from "./rText.vue";
import rFiles from "./rFiles.vue";
import rM2MAutocomplete from "./rM2MAutocomplete.vue";
export default {
  template: 'asfs',
  props: {
    widget: { type: String, default: "input" },
    name: { type: String, required: true }
  },
  computed: {
    ComponentName: function () {
      var a = null;
      switch (this.widget) {
        case "m2o":
          a = rAutocomplete;
          break;
        case "handsontable":
          a = rHandsontable;
          break;
        case "toggle":
          a = rToggle;
          break;
        case "radio":
          a = rRadio;
          break;
        case "select":
          a = rSelect;
          break;
        case "checkbox":
          a = rCheckbox;
          break;
        case "image":
          a = rImage;
          break;
        case "m2o_img":
          a = rM2oImage;
          break;
        case "text":
          a = rText;
          break;
        case "files":
          a = rFiles;
          break;
        case "m2m":
          a = rM2MAutocomplete;
          break;
        //case "input":
        default:
          a = rInput;
      }
      return a;
    }
  },
  data: function () {
    return {
      self: this.$parent.self,
      field: null
    };
  },
  methods: {
    getValue: function () {
      var self = this;
      return self.form.formData[self.name];
    },
    setValue: function (val) {
      var self = this;
      self.$set(self.form.formData, self.name, val);
    }
  },
  mounted: function () {
    //window.console.log(this.form);
    this.field = this.$refs.field;
  }
};
</script>
<style>
.field {
  position: absolute;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
</style>