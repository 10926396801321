<template>
  <div
    class="field item-input"
    :class="classMain"
  >
    <div class="item-inner">
      <label
        v-tooltip="help"
        class="item-title item-label"
        :for="name"
      >{{
          label
      }}</label>
      <div class="item-input-wrap">
        <Select2
          ref="select"
          multiple="multiple"
          :value="getValue().map((o) => o[primary])"
          :options="getOptions()"
          :settings="settings"
          :style="'display: flex;width:' + width"
          @select="onSelect"
          @change="onChange"
          v-on="$listeners"
          :disabled="
  readonly ||
  form.mode == 'view' ||
  form.mode == 'empty' ||
  (form.mode == 'search' && !searchable)
          "
          tabindex="0"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Select2 from "v-select2-component";
import FieldMixin from "./FieldMixin.vue";
export default {
  components: { Select2 },
  inject: ["rformc"],
  mixins: [FieldMixin],
  props: {
    /*rform: {},
    name: { type: String },
    width: { type: String },
    required: { type: Boolean },
    readonly: { type: Boolean },
    searchable: { type: Boolean, default: false },
    help: {}*/
    label: { type: String },
    "inline-label": { type: Boolean },
    primary: { type: String, default: "codigo" },
    labelProp: { type: String, default: "nombre" },
    searchProp: { type: String },
    imgProp: { type: String },
    placeholder: { type: String },
    height: { type: Number, default: 100 },
    templateSelection: {
      type: Function,
    },
    templateResult: {
      type: Function,
    },
    dbAdapter: {
      type: String,
    },
    fields: { type: Array, default: () => [] },
    filter: { type: Array, default: () => [] },
  },
  computed: {
    classMain: function () {
      var classMain = {};
      classMain["inline-label"] = this.inlineLabel;
      classMain["readonly"] = this.readonly || this.form.readonly;
      classMain["mode-" + this.form.mode] = true;
      return classMain;
    },
  },
  data: function () {
    var self = this;
    return {
      isLoading: false,
      form: this.rform ? this.rform : this.rformc,
      settings: {
        containerCss: { height: self.height },
        placeholder: self.placeholder,
        multiple: true,
        templateResult:
          self.templateResult ||
          function (item) {
            if (!item[self.primary]) return item[self.labelProp];
            return window.$(
              '<div style="display: flex; width: 100%; overflow: hidden;">\
                <span class="codelabel">' +
              item[self.primary] +
              "</span> " +
              item[self.labelProp] +
              (self.imgProp && item[self.imgProp]
                ? "<span class='item-after'><img height='19' class='select2-thumbnail' src='" +
                item[self.imgProp].imagen +
                "'></span>"
                : "") +
              "</div>"
            );
          },
        templateSelection:
          self.templateSelection ||
          function (item) {
            if (!item[self.primary]) return item[self.labelProp];
            return window.$(
              '<div style="display: flex; width: 100%; overflow: hidden;">\
                <span class="codelabel">' +
              item[self.primary] +
              "</span> " +
              item[self.labelProp] +
              (self.imgProp && item[self.imgProp]
                ? "<span class='item-after'><img height='19' class='select2-thumbnail' src='" +
                item[self.imgProp].imagen +
                "'></span>"
                : "") +
              "</div>"
            );
          },
        ajax: {
          data(params) {
            var fields = [];
            fields.push(self.labelProp);
            //if (self.imgProp) fields.push(self.imgProp);
            if (self.imgProp) {
              //fields.push(self.imgProp.split(".")[0]); //TODO optimizar como en handsontable
              let ar = self.imgProp.split(".");
              let ctx = fields;
              for (let i = 0; i < ar.length; i++) {
                if (i == ar.length - 1) {
                  ctx.push(ar[i]);
                } else {
                  ctx.push({
                    name: ar[i],
                    fields: [],
                  });
                  ctx = ctx[ctx.length - 1].fields;
                }
              }
            }
            fields = [...fields, ...self.fields];
            let filter = [
              "OR",
              [self.searchProp || self.labelProp, "%LIKE%", params.term],
              [self.primary, "%LIKE%", params.term],
            ];
            if (self.filter.length) filter = [...[filter], ...[self.filter]];
            let res = {
              filter,
              fields,
              depth: 2,
              limit: 60,
              order: self.primary,
              orderDir: "ASC",
            };
            if (!self.dbAdapter) res.field = self.name;
            return res;
          },
          transport: function (params, success, failure) {
            window.DB.getList(
              self.dbAdapter || self.form.dbAdapter,
              params.data,
            ).then(function (res) {
              self.isLoading = false;
              var results = [];
              res.data.forEach(function (item) {
                if (item[self.primary])
                  results.push({ ...item, ...{ id: item[self.primary] } });
              });
              success({
                results: results,
              });
            });
          },
        },
        language: {
          loadingMore: function () {
            return "Cargando...";
          },
          noResults: function () {
            return "Sin resultados";
          },
          searching: function () {
            return "Buscando...";
          },
        },
      },
    };
  },
  methods: {
    getOptions() {
      var self = this;
      var res = [];
      for (let val of self.getValue()) {
        res.push({ ...val, ...{ id: val[self.primary] } });
      }
      return res;
    },
    onSelect(e) {
      var self = this;
      if (!e.selected) {
        var idx = self
          .getValue()
          .findIndex((a) => a[self.primary] == e[self.primary]);
        self.getValue().splice(idx, 1);
        self.setValue(self.getValue().length ? self.getValue() : false);
        self.$emit("change", "edit");
        self.$emit("unselect", "edit");
      } else {
        delete e.selected;
        var val = [...self.getValue(), ...[e]];
        self.setValue(val);
        self.$emit("change", "edit");
        self.$emit("select", "edit");
      }
      self.form.$emit("change", self.name, "edit", e);
      setTimeout(function () {
        self.focusNextField(window.$(self.$el).find("[tabindex=0]")[0]);
      }, 0);
    },
    getValue: function () {
      return this.getContext()[this.getName()] || [];
    },
    /*setValue: function(val) {
      var self = this;
      self.$set(self.form.formData, self.name, val);
    },*/
    bindFields() {
      var self = this;
      var fields = [self.primary, self.labelProp];
      if (self.imgProp)
        fields.push({
          name: self.imgProp,
          fields: ["imagen"],
        });
      fields = [...fields, ...self.fields];
      self.form.fields.push({
        name: self.name,
        fields,
      });
    },
  },
  mounted: function () {
    //window.console.log(this.form);
    var self = this;
    var abre = false;
    self.$refs.select.$el.addEventListener("keydown", function (e) {
      if (e.key === " ") {
        abre = true;
        self.$refs.select.select2.select2("open");
      }
      if (e.key === "Enter") {
        if (e.shiftKey) self.focusPrevField(e.target);
        else self.focusNextField(e.target);
      }
    });
    window.$(self.$el).on("click", function (e) {
      abre = true;
      self.$refs.select.select2.select2("open");
    });

    window.$(self.$el).on("select2:opening", function (e) {
      if (abre) {
        abre = false;
        return true;
      }
      abre = false;
      return false;
    });
  },
};
</script>
<style>
/* rM2MAutocomplete */
.multiselect {
  padding: 1px 3px !important;
  height: auto;
  border-radius: 3px !important;
  /*border: none !important;
    border-bottom: solid 1px #CCC !important;*/
  border: 1px solid #ccc;
  position: relative;
  z-index: 99;
  background-color: white;
  box-sizing: border-box !important;
}

.multiselect input {
  border: none;
  margin: 0;
  padding: 0;
}

.field.mode-search .select2-selection {
  background-color: #ffffee;
}

/**********/
/* SELECT 2 */

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-right: 0px;
  margin-top: 3px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0 3px;
  margin-bottom: -2px;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 3px !important;
}

.select2-container .select2-selection--multiple {
  min-height: 22px;
  overflow: auto;
  /*line-height: 1.8;*/
}

/*.select2-container .select2-choice {
  height: 22px;
}*/

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid #ccc 1px;
}

.select2-container--default .select2-selection--multiple {
  border: solid #ccc 1px;
}

.select2 ul {
  max-height: 480px;
  overflow: auto !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: rgba(0, 140, 186, 0.1);
  color: black;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #f7f7f7;
}

.select2-results__option {
  padding: 4px;
  line-height: 1;
}

.select2-search--dropdown .select2-search__field {
  padding: 3px;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background: none;
  border-color: transparent;
}

.codelabel {
  border-radius: 3px;
  padding: 0px 4px;
  margin: 2px;
  background-color: #eee;
  border: 1px solid #ccc;
  line-height: 1.1;
  font-size: 0.8em;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #f7f7f8;
  border: 1px solid #ddd;
  border-radius: 3px;
  display: flex;
}

.select2-thumbnail:hover {
  height: 100px;
  margin-top: -50px;
  right: 5px;
  position: absolute;
  box-shadow: 5px 5px 5px #ddd;
}

.select2-container--default.select2-container--disabled {
  background-color: #eee !important;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  border: 1px solid #ccc;
  border-radius: 3px;
}
</style>
