<template>
  <header>
    <div class="title">
      {{ win.title }}
      <span v-if="win.subtitle && win.subtitle != 'empty'">{{
        " / " + $t("rform." + win.subtitle)
      }}</span>
    </div>
    <div class="right">
      <button
        v-if="minimize"
        class="button button-minimize"
        @click="win.instance.$emit('minimize')"
      >
        <fa-icon :icon="['fas', 'window-minimize']"></fa-icon>
      </button>
      <button class="button button-close" @click="closeWindow">
        <fa-icon icon="times"></fa-icon>
      </button>
    </div>
  </header>
</template>
<script>
export default {
  inject: ["app", "win"],
  components: {},
  props: {
    minimize: { type: Boolean, default: true },
    /*win: {
      required: true
    }*/
  },
  methods: {
    closeWindow() {
      var self = this;
      let win = self.win;
      if (
        (win.instance.mode == "new" || win.instance.mode == "edit") &&
        JSON.stringify(win.instance.lastFormData) !=
          JSON.stringify(win.instance.formData)
      ) {
        self.app.confirm("¿Seguro que desea cerrar la ventana?", function () {
          win.instance.$emit("close");
        });
      } else {
        win.instance.$emit("close");
      }
    },
  },
};
</script>
