const messages = {
    en: {
        global: {
            pantalla_completa: 'Fullscreen',
            minimizar_todo: 'Minimize all',
            bloquear: 'Lock',
            cerrar_sesion: 'Sign off'
        }
    },

    es: {
        back: 'Atrás',
        next: 'Siguiente',
        global: {
            pantalla_completa: 'Pantalla completa',
            minimizar_todo: 'Minimizar todo',
            bloquear: 'Bloquear',
            cerrar_sesion: 'Cerrar sesión',
            permitir_movilidad_widgets: 'Permitir movilidad widgets'
        },
        rform: {
            save: 'Guardar',
            delete: 'Eliminar',
            new: 'Nuevo',
            edit: 'Editar',
            search: 'Buscar',
            view: 'Visualizar',
            empty: 'Vacío',
            cancel: 'Cancelar',
            reports: 'Informes',
            actions: 'Acciones'
        },
        rfiles: {
            anadir_documento: 'Añadir documento'
        },
        rimage: {
            seleccionar_imagen: 'Seleccionar imagen',
            rotar_derecha: 'Rotar hacia la derecha',
            rotar_izquierda: 'Rotar hacia la izquierda'
        }
    }
}
export default messages;