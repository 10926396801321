import Handsontable from "handsontable";
(function (Handsontable) {
    "use strict";

    var sourceDefault = function (cellProperties, params, process) {
        let value = params.query;
        let c = cellProperties;
        let self = cellProperties.self;
        var filter = [
            [
                "OR", [c.label || "nombre", "%LIKE%", value],
                [c.primary || "id", "%LIKE%", value]
            ]
        ];
        if (c.filter) {
            if (typeof c.filter == "function")
                filter.push(c.filter(
                    params.hot.getSourceDataAtRow(params.row),
                    params.row,
                    params.prop
                ));
            else if (Array.isArray(c.filter)) {
                filter.push(c.filter);
            }
        }
        let fields = [...[c.label || "nombre"], ...(c.fields || [])];
        if (c.image) {
            let ar = c.image.split(".");
            let ctx = fields;
            for (let i = 0; i < ar.length; i++) {
                if (i == ar.length - 1) {
                    ctx.push(ar[i]);
                } else {
                    ctx.push({
                        name: ar[i],
                        fields: []
                    });
                    ctx = ctx[ctx.length - 1].fields;
                }
            }
        }
        window.DB.getList(c.model || self.form.dbAdapter, {
            filter: filter,
            fields: fields,
            field: c.model ? undefined : self.name + "." + c.name
        }).then(function (res) {
            process(res.data);
        });
    };

    var fetchData = function (instance, c, row, prop, value) {
        let self = c.self;
        let fields = [...[c.label || "nombre"], ...(c.fields || [])];
        if (c.image) {
            let ar = c.image.split(".");
            let ctx = fields;
            for (let i = 0; i < ar.length; i++) {
                if (i == ar.length - 1) {
                    ctx.push(ar[i]);
                } else {
                    ctx.push({
                        name: ar[i],
                        fields: []
                    });
                    ctx = ctx[ctx.length - 1].fields;
                }
            }
        }
        window.DB.get(c.model || self.form.dbAdapter, {
            itemId: value,
            fields: fields,
            field: c.model ? undefined : self.name + "." + c.name
        })
            .then(function (res) {
                instance.cachedItems[prop][value] = res;
                let index = instance.pendingCache[prop].indexOf(value);
                instance.pendingCache[prop].splice(index, 1);
                instance.render();
            })
            .catch(function (res) {
                if (c.emptyOnError) instance.setDataAtRowProp(row, prop, null);
                else instance.cachedItems[prop][value] = false;
                instance.render();
            });
    };

    var tplTdDefault = (data, c) => (c.image ?
        "<img class='autocomplete2-image' src=" +
        (window.App.$utils.misc.rget(data, c.image) ?
            window.App.$utils.misc.rget(data, c.image) :
            require("./../../assets/noimage.png")) +
        ">" :
        "") +
        (c.showCode ?
            "<b class='autocomplete2-code'>" +
            data[c.primary || "id"] +
            "</b> " :
            "") +
        (data[c.label || "nombre"] || '');

    class M2oEditor extends Handsontable.editors.AutocompleteEditor {
        constructor(instance) {
            super(instance);
            this.selectedItem = null;
        }

        finishEditing(restoreOriginalValue, ctrlDown, callback) {
            if (this.htEditor && this.htEditor.isListening()) { // if focus is still in the HOT editor
                this.hot.listen(); // return the focus to the parent HOT instance
            }
            let val = this.TEXTAREA.value.trim();
            //window.console.log('finishingEditing', this.htEditor, this.isOpened());
            if (this.isOpened() && this.htEditor && this.htEditor.getSelectedLast()) {
                const value = this.htEditor.getInstance().getValue();
                if (value !== void 0) { // if the value is undefined then it means we don't want to set the value
                    this.selectedItem = value;
                }
            } else if (val) {
                this.selectedItem = val;
            }
            this.setValue(this.selectedItem);
            return Handsontable.editors.BaseEditor.prototype.finishEditing.apply(this, arguments);
        }

        prepare(td, row, col, prop, value, cellProperties) {
            let that = this;
            super.prepare(td, row, col, prop, value, cellProperties);

            const parent = this;
            this.htOptions.afterOnCellMouseDown = function (_, coords) {
                parent.instance.destroyEditor();
            };
            this.htOptions.getValue = function () {
                var selection = this.getSelectedLast();
                if (!selection) return null;
                return this.getSourceDataAtRow(selection[0]);
            };
            let c = cellProperties;
            this.htOptions.columns = [{
                data: c.primary || "id",
                renderer: function (
                    instance,
                    td,
                    row,
                    col,
                    prop,
                    value,
                    cellProperties
                ) {
                    let data = instance.getSourceDataAtRow(row);
                    td.innerHTML = c.tplTd ? c.tplTd(data, c) : tplTdDefault(data, c);

                    if (that.selectedItem == data[c.primary || "id"]) {
                        td.style.backgroundColor = '#ddd';
                    }
                    return td;
                }
            }]
        }
        getValue() {
            let that = this;
            if (this.selectedItem && typeof this.selectedItem == 'object') {
                that.hot.cachedItems[that.prop][this.selectedItem[that.cellProperties.primary || 'id']] = this.selectedItem;
                return this.selectedItem[that.cellProperties.primary || 'id'];
            } else {
                return this.selectedItem;
            }
        }
        setValue(newValue) {
            this.selectedItem = !newValue ? null : newValue
        }

        open() {
            //const priv = privatePool.get(this);

            // this.addHook('beforeKeyDown', event => this.onBeforeKeyDown(event));
            // Ugly fix for handsontable which grab window object for autocomplete scroll listener instead table element.
            this.TEXTAREA_PARENT.style.overflow = 'auto';
            Handsontable.editors.HandsontableEditor.prototype.open.apply(this, arguments);
            this.TEXTAREA_PARENT.style.overflow = '';

            const choicesListHot = this.htEditor.getInstance();
            const trimDropdown = this.cellProperties.trimDropdown === void 0 ? true : this.cellProperties.trimDropdown;


            this.showEditableElement();
            this.focus();

            const scrollbarWidth = Handsontable.dom.getScrollbarWidth(this.hot.rootDocument);

            choicesListHot.updateSettings({
                colWidths: trimDropdown ? [Handsontable.dom.outerWidth(this.TEXTAREA) - 2] : void 0,
                width: trimDropdown ? Handsontable.dom.outerWidth(this.TEXTAREA) + scrollbarWidth + 2 : void 0,
                autoColumnSize: true,
                modifyColWidth(width, col) {
                    // workaround for <strong> text overlapping the dropdown, not really accurate
                    const autoColumnSize = this.getPlugin('autoColumnSize');
                    let columnWidth = width;

                    if (autoColumnSize) {
                        const autoWidths = autoColumnSize.widths;

                        if (autoWidths?.[col]) {
                            columnWidth = autoWidths[col];
                        }
                    }

                    return trimDropdown ? columnWidth : columnWidth + 15;
                }
            });

            // Add additional space for autocomplete holder
            this.htEditor.view.wt.wtTable.holder.parentNode.style['padding-right'] = `${scrollbarWidth + 2}px`;

            /*if (priv.skipOne) {
              priv.skipOne = false;
            }*/
            this.hot._registerTimeout(() => {
                this.queryChoices(this.TEXTAREA.value);
            });
        }

        queryChoices(query) {
            if (this.cellProperties.noOpen) return;
            const source = this.cellProperties.source || sourceDefault;
            this.query = query;

            if (typeof source === 'function') {
                let params = {
                    rowObj: this.instance.getData()[this.row],
                    row: this.row,
                    prop: this.prop,
                    hot: this.hot,
                    query: query
                };

                source.call(this, this.cellProperties, params, (choices) => {
                    this.rawChoices = choices;
                    this.updateChoicesList(choices);
                });

            } else if (Array.isArray(source)) {
                this.rawChoices = source;
                this.updateChoicesList(this.stripValuesIfNeeded(source));
            } else {
                this.updateChoicesList([]);
            }
        }

        updateChoicesList(choicesList) {
            const pos = Handsontable.dom.getCaretPosition(this.TEXTAREA);
            const endPos = Handsontable.dom.getSelectionEndPosition(this.TEXTAREA);
            let choices = choicesList;

            this.strippedChoices = choices;
            this.htEditor.loadData(choices || []);

            this.updateDropdownHeight();
            this.flipDropdownIfNeeded();

            this.hot.listen(false);

            Handsontable.dom.setCaretPosition(this.TEXTAREA, pos, (pos === endPos ? void 0 : endPos));
        }
    }

    Handsontable.editors.M2oEditor = M2oEditor;
    Handsontable.editors.registerEditor('m2o', M2oEditor);


    /* RENDERER */

    var M2oRenderer = function (instance, td, row, column, prop, value, cellProperties) {
        let c = cellProperties;
        instance.cachedItems = instance.cachedItems || {};
        instance.cachedItems[prop] = instance.cachedItems[prop] || {};

        if (value && typeof value == "object") {
            instance.cachedItems[prop][value[c.primary || "id"]] = value;
            value = value[c.primary || "id"];
            //isntance.setDataAtRowProp();
        }

        let html = '';
        if (
            value in instance.cachedItems[prop] &&
            instance.cachedItems[prop][value] == false
        ) {
            td.classList.add("wrong");
            td.setAttribute("title", "No existe el registro");
            html = value;
        } else {
            td.classList.remove("wrong");
            td.removeAttribute("title");
            if (value in instance.cachedItems[prop]) {
                let data = instance.cachedItems[prop][value];
                html = c.tplTd ? c.tplTd(data, c) : tplTdDefault(data, c);
            } else if (value) {
                instance.pendingCache = instance.pendingCache || {};
                instance.pendingCache[prop] =
                    instance.pendingCache[prop] || [];
                if (instance.pendingCache[prop].indexOf(value) === -1) {
                    instance.pendingCache[prop].push(value);
                    //Fetch
                    fetchData(instance, c, row, prop, value);
                } else {
                    html = value;
                }
            } else {
                //html += '';
            }
        }
        td.innerHTML = html;
        Handsontable.renderers.cellDecorator.apply(this, arguments);
        return td;
    }
    Handsontable.renderers.M2oRenderer = M2oRenderer;
    Handsontable.renderers.registerRenderer('m2o', M2oRenderer);


    /* CELLTYPE */

    Handsontable.cellTypes.registerCellType('m2o', {
        editor: Handsontable.editors.M2oEditor,
        renderer: Handsontable.renderers.M2oRenderer
    });


})(Handsontable);