<template>
  <grid-layout id="desktop-grid" :layout.sync="app.widgets" :col-num="6" :is-draggable="editarWidgets"
    :is-resizable="false" :margin="[10, 10]" :use-css-transforms="true" :vertical-compact="true" :row-height="200"
    @layout-updated="layoutUpdatedEvent">
    <grid-item v-for="item in app.widgets" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :key="item.i"
      dragIgnoreFrom="a,button,.handsontable,.field" :class="{ editarWidgets }">
      <component style="width: 100%; height: 100%" v-if="comps[item.widget]" :is="comps[item.widget]" v-bind="item.props">
      </component>
    </grid-item>
  </grid-layout>
</template>
<script>
import VueGridLayout from "vue-grid-layout";
export default {
  inject: ["app"],
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  props: {
    editarWidgets: Boolean,
  },
  data: function () {
    var self = this;
    return {
      comps: {},
    };
  },
  methods: {
    layoutUpdatedEvent(layout) {
      var self = this;


      window.console.log(layout);
      // TODO save layout

      layout.forEach((l) =>
        l.widget in self.comps
          ? null
          : import("./../widgets/" + l.widget + ".vue").then(function (res) {
            self.$set(self.comps, l.widget, res.default);
          })
      );
    },
  },
};
</script>
<style>
#desktop-grid {
  margin: auto;
  width: 1500px;
  overflow: auto;
  max-height: 100%;
  min-height: 100%;
}

.vue-grid-layout {
  background: #eee;
}

.vue-grid-item:not(.vue-grid-placeholder) {
  /*background: #fff;
  border: 1px solid #ccc;*/
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}

.vue-grid-item.vue-grid-placeholder {
  background: var(var(--color-primary)) !important;
}

.editarWidgets:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  background: linear-gradient(90deg, var(--color-primary) 50%, transparent 50%),
    linear-gradient(90deg, var(--color-primary) 50%, transparent 50%),
    linear-gradient(0deg, var(--color-primary) 50%, transparent 50%),
    linear-gradient(0deg, var(--color-primary) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
  animation: border-dance 30s infinite linear;
}

@keyframes border-dance {
  0% {
    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
  }

  100% {
    background-position: 100% 0, 0 100%, 0 0, 100% 100%;
  }
}
</style>