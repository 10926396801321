<template>
  <aside id="left-panel" :class="{fijo:fijo}">
    <span class="arrow"></span>
    <fa-icon
      :icon="fijo? 'chevron-left' : 'chevron-right'"
      class="arrow"
      @click="toggle"
      v-tooltip="fijo? 'Ocultar panel' : 'Mostrar panel'"
    />
    <div id="logo">
      <img :src="app.empresa.imagen || require('../assets/noimage.png')" />
    </div>
    <div id="main-menu" style="flex:1;overflow:auto;">
      <ul class="list list-links">
        <li
          v-for="(enlace,i) in app.enlaces.filter(a => parseInt(a.activo)).sort((a, b) => (parseInt(a.posicion) > parseInt(b.posicion) ? 1 : -1))"
          :key="'menu-enlace-'+i"
        >
          <a @click="app.openWindow(enlace.model)" v-if="enlace.descripcion!='_line_'" style="display: flex; align-items: center;">
            <!--<fa-icon :icon="enlace.icono" style="width:1rem;" />-->
            <img :src="'iconos/'+enlace.icono+'.png'" width="24" style="margin-right: 6px;">
            {{enlace.descripcion}}
          </a>
          <hr v-else>
        </li>
      </ul>
    </div>
  </aside>
</template>
<script>
export default {
  inject: ["app"],
  data: function() {
    return {
      fijo: true,
      enlaces: [
        {
          descripcion: "Clientes",
          icono: "users",
          window: "cliente"
        },
        {
          descripcion: "Proveedores",
          icono: "user-tie",
          window: "proveedor"
        },
        {
          descripcion: "Empresa",
          icono: "home",
          window: "empresa"
        },
        {
          descripcion: "Marcas",
          icono: "tag",
          window: "marca"
        },
        {
          descripcion: "Artículo quickview",
          icono: "tag",
          window: "articulo_quickview"
        }
      ]
    };
  },
  methods: {
    toggle() {
      this.fijo = !this.fijo;
    }
  },
  mounted: function() {}
};
</script>
<style>
#left-panel.fijo + #desktop {
  margin-left: 220px;
  width: calc(100% - 220px);
}

#left-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 230px;
  padding: 80px 26px 10px 10px;
  /*padding-top: 68px;*/
  margin-left: -205px;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(255, 255, 255, 1);
  border-right: 1px solid #ccc;
  /*box-shadow:1px 0px 5px #AAA;*/
  -webkit-transition: all 0.4s;
  transition: all 0.4s ease-in;
  z-index: 9960;
}

/*#left-panel.fijo, #left-panel:hover {*/

#left-panel.fijo {
  margin-left: 0;
  padding: 80px 16px 10px 10px;
  width: 220px;
}

#left-panel > .arrow {
  font: Helvetica, Arial, sans-serif;
  color: rgba(0, 0, 0, 0.75);
  display: block;
  position: absolute;
  float: right;
  top: 77px;
  right: 5px;
  cursor: pointer;
}

#left-panel.fijo > .arrow {
  color: #3c7a95 !important;
}

#left-panel > #logo > img {
  width: 100%;
}

#left-panel a {
  cursor: pointer;
  /*padding:5px;
	margin: 0px;
	border:solid 1px rgba(0,0,0,0.00);
	display:block;*/
}
</style>