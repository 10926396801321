<template>
  <div class="drop_menu">
    <ul id="menubar" class="drop_menu_inner">
      <li>
        <div>
          <fa-icon icon="home" />
          <span>General</span>
        </div>
        <ul class="list">
          <li class="has-children">
            <span>Empresa</span>
            <ul class="list">
              <li open-window="empresa">Empresa</li>
              <li open-window="tesoreria">Tesorería</li>
              <li open-window="ubicacion_tesoreria">Ubicaciones</li>
            </ul>
          </li>

          <li open-window="delegacion">Delegaciones</li>
          <li open-window="almacen">Almacenes</li>

          <hr class="separador" />
          <li class="has-children">
            Proveedores
            <ul class="list">
              <li open-window="proveedor">Proveedores</li>
              <li open-window="saldos_proveedores">Saldos</li>
              <li open-window="documentos_proveedor">Movimientos</li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_documento', { props: { model: 'proveedor' } })">Documentos de
                Proveedores
              </li>
              <li @click="app.openWindow('emitir_listado', { props: { model: 'proveedor' } })">Listados de Proveedores
              </li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'proveedor' } })">Etiquetas de Proveedores
              </li>
              <hr class="separador" />
              <li open-window="cliente">Envio de Mailing a Proveedores</li>
              <li open-window="cliente">Envio de Wasapp a Proveedores</li>
            </ul>
          </li>
          <li class="has-children">
            Clientes
            <ul class="list">
              <li open-window="cliente">Clientes</li>
              <li open-window="consulta_articulos_por_cliente">Consulta de Clientes</li>
              <li open-window="visita">Visitas a Clientes</li>
              <li open-window="localizacion_cliente">Mapa de Clientes</li>
              <li open-window="saldos_clientes">Saldos de clientes</li>
              <li open-window="documentos_cliente">Movimientos</li>
              <hr class="separador" />
              <li open-window="tipo_cliente">Tipos de Clientes</li>
              <li open-window="galeria_clientes">Galería de Clientes</li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_documento', { props: { model: 'cliente' } })">Documentos de clientes
              </li>
              <li @click="app.openWindow('emitir_listado', { props: { model: 'cliente' } })">Listados de Clientes</li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'cliente' } })">Etiquetas de Clientes</li>
              <hr class="separador" />
              <li open-window="">Envio de Mailing a clientes</li>
              <li open-window="">Envio de Wasapp a clientes</li>
              <hr class="separador" />
              <li open-window="actualizar_ivare">Actualizar IVA/RE</li>
            </ul>
          </li>
          <li class="has-children">
            Representantes
            <ul class="list">
              <li open-window="representante" data-primary="code">
                Representantes
              </li>
              <li open-window="saldos_representantes">Saldos</li>
              <li open-window="documentos_representante">Movimientos</li>
              <li open-window="localizacion_representante">Localización</li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_listado', { props: { model: 'representante' } })">Listado de
                Representantes
              </li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'representante' } })">Etiquetas de
                Representantes</li>
              <hr class="separador" />
              <li open-window="">Envio de Mailing a Representantes</li>
              <li open-window="">Envio de Wasapp a Representantes</li>
              <hr class="separador" />
              <li open-window="comision">Definición de comisiones</li>
              <li open-window="liquidacion_comisiones">
                Liquidación de comisiones
              </li>
              <li open-window="liquidacion_comision">
                Mantenimiento Liquidación de comisiones
              </li>
            </ul>
          </li>
          <hr class="separador" />
          <li open-window="seccion" data-primary="id_category">Secciones</li>
          <li class="has-children">
            Familias
            <ul class="list">
              <li open-window="familia" data-primary="id_category">Familias</li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_listado', { props: { model: 'familia' } })">Listado de Familias</li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'familia' } })">Etiquetas de Familias</li>
            </ul>
          </li>

          <li class="has-children">
            Modelos
            <ul class="list">
              <li open-window="modelo" data-primary="id_modelo">Modelos</li>
              <li open-window="galeria_articulo">Galería de Artículos</li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_documento', { props: { model: 'modelo' } })">Documentos de Modelos</li>
              <li @click="app.openWindow('emitir_listado', { props: { model: 'modelo' } })">Listado de Modelos</li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'modelo' } })">Etiquetas de Modelos</li>
            </ul>
          </li>
          <li class="has-children">
            Articulos
            <ul class="list">
              <li open-window="articulo">Articulos</li>
              <li open-window="consulta_articulos">Consulta de Articulos</li>
              <li open-window="consulta_articulos_por_modelo">
                Consulta de Artículos por Modelo
              </li>
              <li open-window="consulta_articulos_por_cliente">Consulta de Articulos por Cliente</li>
              <li open-window="consulta_articulos_por_proveedor">
                Consulta de Articulos por Proveedor
              </li>
              <li open-window="galeria_articulo">Galería de Artículos</li>

              <hr class="separador" />
              <li open-window="recalculo_tarifa">Recalculo Tarifas Precios</li>
              <li open-window="categoria">Categorías</li>
              <li open-window="coleccion">Colecciones</li>
              <li open-window="conjunto">Conjuntos</li>
              <li open-window="valor_atributo">Valores de atributos</li>
              <li open-window="ordenar_colecciones">Ordenar colecciones</li>
              <li open-window="ordenar_categorias">Ordenar categorías</li>
              <li open-window="articulos_ocultos">Artículos activos por representante</li>
              <li open-window="articulos_web">Configuración web</li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_documento', { props: { model: 'articulo' } })">
                Documentos de Artículo
              </li>
              <li @click="app.openWindow('emitir_listado', { props: { model: 'articulo' } })">Listado de Artículos</li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'articulo' } })">
                Etiquetas de Artículos
              </li>
            </ul>
          </li>
          <li class="has-children">
            Inventario
            <ul class="list">
              <li open-window="inventario" data-view="consulta_view">
                Inventario de artículos
              </li>
              <li open-window="entrada_inventario">
                Entrada de Inventario Físico
              </li>
              <li open-window="regularizacion_inventario">
                Regularización de inventario
              </li>
              <li open-window="albaran_regularizacion">
                Albaranes de regularización
              </li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_listado', { props: { model: 'inventario' } })">Listado de Inventario
              </li>
            </ul>
          </li>
          <li class="has-children">
            Certificados
            <ul class="list">
              <li open-window="naturaleza">Naturaleza</li>
              <li open-window="talla">Talla</li>
              <li open-window="color">Color</li>
              <li open-window="pureza">Pureza</li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_listado', { props: { model: 'naturaleza' } })">Listado de Naturalezas
              </li>
              <li @click="app.openWindow('emitir_listado', { props: { model: 'talla' } })">Listado de Tallas</li>
              <li @click="app.openWindow('emitir_listado', { props: { model: 'color' } })">Listado de Colores</li>
              <li @click="app.openWindow('emitir_listado', { props: { model: 'pureza' } })">Listado de Purezas</li>
            </ul>
          </li>
          <li class="has-children">
            Marcas
            <ul class="list">
              <li open-window="marca">Marcas</li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_listado', { props: { model: 'marca' } })">Listado de Marcas</li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'marca' } })">Etiquetas de Marcas</li>
            </ul>
          </li>
          <li class="has-children">
            Atributos
            <ul class="list">
              <li open-window="atributo">Atributos</li>
              <li open-window="valor_atributo">Valores de atributos</li>
              <li open-window="articulos_atributos">Editor masivo de atributos</li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_listado', { props: { model: 'atributo' } })">Listado de Atributos</li>
              <hr class="separador" />
              <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'atributo' } })">Etiquetas de Atributos
              </li>
            </ul>
          </li>
          <hr class="separador" />
          <li open-window="moneda" data-width="500">Monedas</li>
          <li open-window="impuesto" data-width="500">Impuestos</li>
          <li open-window="entidad_bancaria" data-width="500">
            Entidades bancarias
          </li>
          <li open-window="provincia" data-primary="id_state">Provincias</li>
          <li open-window="pais" data-primary="id_country">Paises</li>
          <li open-window="metal">Metales</li>
          <hr class="separador" />
          <li open-window="tipo_doc">Tipos de documentos</li>
          <!--<li open-window="divisa">Divisas</li>-->
          <li open-window="forma_pago">Formas de Pago</li>
          <li open-window="coste_adicional">Costes Adicionales</li>
          <li open-window="coste_reparacion">Costes de Reparación</li>
        </ul>
      </li>
      <li>
        <div>
          <fa-icon icon="credit-card" />
          <span>Compras</span>
        </div>
        <ul class="list">
          <li a open-window="pedido_proveedor">Pedidos Proveedor</li>
          <li open-window="generador_albaran_proveedor">
            Generar Alb. Proveedor
          </li>
          <hr class="separador" />
          <li a open-window="albaran_proveedor">Albaranes de Proveedor</li>
          <li open-window="generador_factura_proveedor">
            Generar Fact. Proveedor
          </li>
          <hr class="separador" />
          <li open-window="factura_proveedor">Facturas de Proveedor</li>
          <hr class="separador" />

          <li class="has-children">
            Documentos Proveedor
            <ul class="list">
              <li @click="app.openWindow('emitir_documento', { props: { model: 'pedido_proveedor' } })">
                Emisión Pedidos Proveedor
              </li>
              <li @click="app.openWindow('emitir_documento', { props: { model: 'albaran_proveedor' } })">
                Emisión Albaranes Proveedor
              </li>
              <li @click="app.openWindow('emitir_documento', { props: { model: 'factura_proveedor' } })">
                Emisión Facturas Proveedor
              </li>
            </ul>
          </li>
          <li class="has-children">
            Listados Proveedor
            <ul class="list">
              <li @click="app.openWindow('emitir_listado', { props: { model: 'pedido_proveedor' } })">Listado Pedidos
                Proveedor
              </li>
              <li @click="app.openWindow('emitir_listado', { props: { model: 'albaran_proveedor' } })">Listado Albaranes
                Proveedor
              </li>
              <li @click="app.openWindow('emitir_listado', { props: { model: 'factura_proveedor' } })">Listado Facturas
                Proveedor
              </li>
            </ul>
          </li>
          <li class="has-children">
            Etiquetas Proveedor
            <ul class="list">
              <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'pedido_proveedor' } })">
                Etiquetas Pedidos Proveedor
              </li>
              <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'albaran_proveedor' } })">
                Etiquetas Albaranes Proveedor
              </li>
              <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'factura_proveedor' } })">
                Etiquetas Facturas Proveedor
              </li>
            </ul>
          </li>
          <hr class="separador" />
          <li class="has-children">
            Devolución Condic.
            <ul class="list">
              <li open-window="devolucion_condicional_compra">Mantenimiento Devoluciones Condicionales Proveedor</li>
              <li open-window="consulta_devolucion_condicional_compra">Consulta Devolución Condicionales Proveedor</li>
              <li open-window="generar_devolucion_condicional_compra">Devolución Condicionales Proveedor</li>
              <li open-window="pedido_proveedor">
                Mantenimiento Devolucion Articulos
              </li>
              <hr class="separador" />
              <li open-window="">Listado Articulos Devueltos</li>
              <li open-window="">Listado Articulos Pendientes Facturar</li>
              <hr class="separador" />
              <li open-window="">Etiquetas Articulos Devueltos</li>
              <li open-window="">Etiquetas Articulos Ptes Facturar</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <div>
          <fa-icon icon="shopping-cart" />
          <span>Ventas</span>
        </div>
        <ul class="list">
          <li open-window="pedido_cliente">Pedidos a Clientes</li>
          <li open-window="generador_albaran_cliente">Generar Alb. Clientes</li>
          <hr class="separador" />
          <li open-window="albaran_cliente">Albaranes a Clientes</li>
          <li open-window="generador_factura_cliente">
            Generar Fact. Clientes
          </li>
          <hr class="separador" />
          <li open-window="factura_cliente">Facturas a Clientes</li>
          <hr class="separador" />
          <li open-window="cobros_recibos_banco">Cobros Recibos Banco</li>
          <li open-window="cobros_cheques_banco">Cobros Cheques Banco</li>
          <hr class="separador" />

          <li class="has-children">
            Documentos Cliente
            <ul class="list">
              <li @click="app.openWindow('emitir_documento', { props: { model: 'pedido_cliente' } })">
                Emisión Pedidos Cliente
              </li>
              <li @click="app.openWindow('emitir_documento', { props: { model: 'albaran_cliente' } })">
                Emisión Albaranes Cliente
              </li>
              <li @click="app.openWindow('emitir_documento', { props: { model: 'factura_cliente' } })">
                Emisión Facturas Cliente
              </li>
            </ul>
          </li>
          <li class="has-children">
            Listados Cliente
            <ul class="list">
              <li @click="app.openWindow('emitir_listado', { props: { model: 'pedido_cliente' } })">Listado Pedidos
                Cliente
              </li>
              <li @click="app.openWindow('emitir_listado', { props: { model: 'albaran_cliente' } })">Listado Albaranes
                Cliente
              </li>
              <li @click="app.openWindow('emitir_listado', { props: { model: 'factura_cliente' } })">Listado Facturas
                Cliente
              </li>
            </ul>
          </li>
          <li class="has-children">
            Etiquetas Cliente
            <ul class="list">
              <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'pedido_cliente' } })">
                Etiquetas Pedidos Cliente
              </li>
              <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'albaran_cliente' } })">
                Etiquetas Albaranes Cliente
              </li>
              <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'factura_cliente' } })">
                Etiquetas Facturas Cliente
              </li>
            </ul>
          </li>

          <hr class="separador" />
          <li class="has-children">
            Devolución Condic.
            <ul class="list">
              <li open-window="devolucion_condicional_venta">
                Mantenimiento Devoluciones Condicionales Clientes
              </li>
              <li open-window="consulta_devolucion_condicional_venta">
                Consulta Devolución Condicionales Cliente
              </li>
              <li open-window="generar_devolucion_condicional_venta">
                Devolución Condicionales Clientes
              </li>
              <li open-window="pedido_proveedor">
                Mantenimiento Devolucion Articulos
              </li>
              <hr class="separador" />
              <li open-window="pedido_proveedor">
                Listado Articulos Devueltos
              </li>
              <li open-window="pedido_proveedor">
                Listado Articulos Pendientes Facturar
              </li>
              <hr class="separador" />
              <li open-window="pedido_proveedor">
                Etiquetas Articulos Devueltos
              </li>
              <li open-window="pedido_proveedor">
                Etiquetas Articulos Pendientes Facturar
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <div>
          <fa-icon icon="tools" />
          <span>Reparaciones</span>
        </div>
        <ul class="list">
          <li open-window="reparacion">Reparaciones</li>
          <li open-window="gestionar_reparaciones">Gestionar reparaciones</li>
          <!--<li data-wizard="gestionar_reparaciones_portlets">
            Gestionar reparaciones 2
          </li>-->
          <hr class="separador" />
          <li @click="app.openWindow('emitir_documento', { props: { model: 'reparacion' } })">
            Documento Reparaciones
          </li>
          <li @click="app.openWindow('emitir_listado', { props: { model: 'reparacion' } })">
            Listado Reparaciones
          </li>
          <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'reparacion' } })">
            Etiqueta Reparaciones
          </li>
        </ul>
      </li>
      <li>
        <div>
          <fa-icon icon="suitcase-rolling" />
          <span>Depósitos</span>
        </div>
        <ul class="list">
          <li open-window="deposito_representante">Depósitos a Representantes</li>
          <hr class="separador" />
          <li @click="app.openWindow('emitir_documento', { props: { model: 'deposito_representante' } })">
            Documento Depósito Representante
          </li>
          <li @click="app.openWindow('emitir_listado', { props: { model: 'deposito_representante' } })">
            Listado Depósito Representante
          </li>
          <li @click="app.openWindow('emitir_etiqueta', { props: { model: 'deposito_representante' } })">
            Etiqueta Depósito Representante
          </li>
        </ul>
      </li>
      <li>
        <div>
          <fa-icon icon="file" />
          <span>Informes</span>
        </div>
        <ul class="list">
          <li open-window="plantilla_informe">Plantillas Documentos</li>
          <li open-window="plantilla_etiqueta">Plantillas Etiquetas</li>
          <li open-window="plantilla_listado">Plantillas Listados</li>
        </ul>
      </li>
      <li>
        <div>
          <fa-icon icon="cogs" />
          <span>Configuración</span>
        </div>
        <ul class="list">
          <li open-window="version">Versión</li>
          <li open-window="configuracion">Configuración</li>
          <li open-window="importar_generico">Importar (global)</li>
          <li open-window="configurar_enlaces_menu">Configurar menú</li>
          <li open-window="configurar_widgets">Configurar widgets</li>
          <li open-window="empresa">Empresa</li>
          <li open-window="usuario">Usuarios</li>
          <li open-window="grupo_usuario">Grupos de usuario</li>
          <!--<li open-window="tarjeta_cordenada">Tarjeta coordenadas</li>-->
          <li open-window="bd">Base de datos</li>
          <li open-window="log">Historial</li>
          <li open-window="log_web">Historial web</li>
          <li open-window="secuencias">Secuencias</li>
          <li data-wizard="enviar_mail">Enviar email</li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  inject: ['app'],
  mounted: function () { },
};
</script>
<style>
.drop_menu {
  position: fixed;
  top: 38px;
  left: 0;
  width: 100%;
  height: 30px;
  z-index: 9970;
  background: #fff;
  padding: 0 10px;
  margin: 0;
  display: flex;
  align-items: center;
  border: solid 1px #ccc;
  border-width: 1px 0px;
}

.drop_menu_inner {
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.drop_menu_inner li {
  position: relative;
  cursor: pointer;
}

.separador {
  padding-top: 0;
  border: none;
  height: 1px;
  background-color: #bbb;
  margin: 3px;
}

/* Submenu */
.drop_menu_inner ul {
  position: absolute;
  list-style-type: none;
  display: none;
  top: 0;
  width: max-content;
  border: solid 1px #ddd;
}

.drop_menu_inner li:hover {
  position: relative;
  background: #eee;
}

.drop_menu_inner li:hover>ul {
  left: 100%;
  background: #fff;
  padding: 0px;
  display: block;
}

.drop_menu_inner li:active>ul {
  /*display: none;*/
}

.drop_menu_inner>li {
  float: left;
  padding: 5px 10px;
}

.drop_menu_inner>li:hover>ul {
  left: 0;
  top: 100%;
}

.drop_menu_inner>li svg+span {
  margin-left: 4px;
}

li.has-children:before {
  content: ">";
  float: right;
  color: #888;
}
</style>