// handsontable/src/editors/autocompleteEditor.js 참고
// handsontable/src/editors/handsontableEditor.js 참고
// select2-editer.js 참고
import Handsontable from "handsontable";
import { faTintSlash } from "@fortawesome/free-solid-svg-icons";
(function(Handsontable) {
    "use strict";

    // Autocomplete2Editor Start
    const AutocompleteEditor = Handsontable.editors.AutocompleteEditor;
    var Autocomplete2Editor = AutocompleteEditor.prototype.extend();

    Autocomplete2Editor.prototype.getValue = function() {
        let obj = this.htEditor.getInstance().getValue();
        if (!obj) return this.TEXTAREA.value;
        this.hot.cachedItems[this.prop][obj[this.cellProperties.primary || 'id']] = obj;
        return obj[this.cellProperties.primary || 'id'];
    };

    Autocomplete2Editor.prototype.setValue = function(newValue) {
        /*if (this.htEditor) {
            let obj = this.htEditor.getInstance().getValue();
            this.TEXTAREA.value = obj[this.cellProperties || 'nombre'];
        }*/

    };

    Autocomplete2Editor.prototype.open = function() {
        //const priv = privatePool.get(this);

        // this.addHook('beforeKeyDown', event => this.onBeforeKeyDown(event));
        // Ugly fix for handsontable which grab window object for autocomplete scroll listener instead table element.
        this.TEXTAREA_PARENT.style.overflow = 'auto';
        Handsontable.editors.HandsontableEditor.prototype.open.apply(this, arguments);
        this.TEXTAREA_PARENT.style.overflow = '';

        const choicesListHot = this.htEditor.getInstance();
        const trimDropdown = this.cellProperties.trimDropdown === void 0 ? true : this.cellProperties.trimDropdown;

        this.showEditableElement();
        this.focus();

        const scrollbarWidth = Handsontable.dom.getScrollbarWidth(this.hot.rootDocument);

        choicesListHot.updateSettings({
            colWidths: trimDropdown ? [Handsontable.dom.outerWidth(this.TEXTAREA) - 2] : void 0,
            width: trimDropdown ? Handsontable.dom.outerWidth(this.TEXTAREA) + scrollbarWidth + 2 : void 0,
            autoColumnSize: true,
            modifyColWidth(width, col) {
                // workaround for <strong> text overlapping the dropdown, not really accurate
                const autoColumnSize = this.getPlugin('autoColumnSize');
                let columnWidth = width;

                if (autoColumnSize) {
                    const autoWidths = autoColumnSize.widths;

                    if (autoWidths[col]) {
                        columnWidth = autoWidths[col];
                    }
                }

                return trimDropdown ? columnWidth : columnWidth + 15;
            }
        });

        // Add additional space for autocomplete holder
        this.htEditor.view.wt.wtTable.holder.parentNode.style['padding-right'] = `${scrollbarWidth + 2}px`;

        /*if (priv.skipOne) {
            priv.skipOne = false;
        }*/
        if (!this.cellProperties.noOpen) {
            this.hot._registerTimeout(() => {
                this.queryChoices(this.TEXTAREA.value);
            });
        }
    }

    Autocomplete2Editor.prototype.queryChoices = function(query) {
        // console.log("Autocomplete2Editor queryChoices", query);
        query = query.toString();
        this.query = query;
        if (typeof this.cellProperties.source == 'function') {
            var that = this,
                params = {
                    rowObj: this.instance.getData()[this.row],
                    row: this.row,
                    prop: this.prop,
                    hot: this.hot
                };
            this.cellProperties.source(query, params, function(choices) {
                that.updateChoicesList(choices);
            });
        } else {
            this.updateChoicesList([]);
        }
    };
    Autocomplete2Editor.prototype.updateChoicesList = function(choices) {
        // console.log("updateChoicesList choices", choices);
        var pos = Handsontable.dom.getCaretPosition(this.TEXTAREA),
            endPos = Handsontable.dom.getSelectionEndPosition(this.TEXTAREA);
        // 동준수정 Start
        this.strippedChoices = choices;
        //동준수정 Start
        // [{object},...] --> [String,...]
        this.htEditor.loadData(choices || []);
        //동준수정 end
        this.updateDropdownHeight();
        this.flipDropdownIfNeeded();
        // if (this.cellProperties.strict === true) {
        //     this.highlightBestMatchingChoice(highlightIndex);
        // }
        // 한글입력시 단어입력이 안되었음..
        //this.instance.listen();
        // this.TEXTAREA.focus();
        this.hot.listen(false);
        Handsontable.dom.setCaretPosition(this.TEXTAREA, pos, (pos != endPos ? endPos : void 0));
    };
    // 동준추가 Start
    /*Autocomplete2Editor.prototype.setValue = function(newValue) {
        window.console.log(1, newValue);
        if (newValue == "[object Object]") newValue = null;
        if (typeof newValue != 'object') {
            if (newValue) {
                newValue = JSON.parse(newValue);
            } else {
                newValue = {};
            }
            window.console.log(this.row, this.prop);
            //this.instance.setDataAtRowProp(this.row, this.prop, newValue);
        }
        //this.TEXTAREA.value = newValue;
    };
    // 동준추가 End
    Autocomplete2Editor.prototype.finishEditing = function(restoreOriginalValue, ctrlDown, callback) {
        if (this.htEditor && this.htEditor.isListening()) { // if focus is still in the HOT editor
            this.hot.listen(); // return the focus to the parent HOT instance
        }

        if (this.htEditor && this.htEditor.getSelectedLast()) {
            const value = this.htEditor.getInstance().getValue();
            window.console.log('finishEditing', value);

            if (value !== void 0) { // if the value is undefined then it means we don't want to set the value
                this.setValue(value);
            }
        }

        return this.finishEditingBaseModified(restoreOriginalValue, ctrlDown, callback);
    };*/

    /*Autocomplete2Editor.prototype.saveValue = function(value, ctrlDown) {
        let selection;
        let tmp;

        // if ctrl+enter and multiple cells selected, behave like Excel (finish editing and apply to all cells)
        if (ctrlDown) {
            selection = this.hot.getSelectedLast();

            if (selection[0] > selection[2]) {
                tmp = selection[0];
                selection[0] = selection[2];
                selection[2] = tmp;
            }
            if (selection[1] > selection[3]) {
                tmp = selection[1];
                selection[1] = selection[3];
                selection[3] = tmp;
            }
        } else {
            selection = [this.row, this.col, null, null];
        }
        window.console.log('saveValue', value);
        this.instance.setDataAtRowProp(this.row, this.prop, value[0][0]);
        //this.hot.populateFromArray(selection[0], selection[1], value, selection[2], selection[3], 'edit');
    }*/

    Handsontable.editors.Autocomplete2Editor = Autocomplete2Editor;
    Handsontable.editors.registerEditor('autocomplete2', Autocomplete2Editor);

})(Handsontable);
/*
import { translateRowsToColumns, cellMethodLookupFactory, spreadsheetColumnLabel } from './helpers/data';
import { CellCoords, ViewportColumnsCalculator } from './3rdparty/walkontable/src';

Handsontable.populateFromArray = function(row, column, input, endRow, endCol, source, method, direction, deltas) {
    if (!(typeof input === 'object' && typeof input[0] === 'object')) {
        throw new Error('populateFromArray parameter `input` must be an array of arrays'); // API changed in 0.9-beta2, let's check if you use it correctly
    }

    const c = typeof endRow === 'number' ? new CellCoords(endRow, endCol) : null;

    return RpopulateFromArray(new CellCoords(row, column), input, c, source, method, direction, deltas);
};
var RpopulateFromArray = function(start, input, end, source, method, direction, deltas) {
    let instance = this;
    window.console.log('roberrrrrrr');
    // TODO: either remove or implement the `direction` argument. Currently it's not working at all.
    let r;
    let rlen;
    let c;
    let clen;
    const setData = [];
    const current = {};

    rlen = input.length;

    if (rlen === 0) {
        return false;
    }

    let repeatCol;
    let repeatRow;
    let cmax;
    let rmax;

    // insert data with specified pasteMode method
    switch (method) {
        case 'shift_down':
            repeatCol = end ? end.col - start.col + 1 : 0;
            repeatRow = end ? end.row - start.row + 1 : 0;
            // eslint-disable-next-line no-param-reassign
            input = translateRowsToColumns(input);
            for (c = 0, clen = input.length, cmax = Math.max(clen, repeatCol); c < cmax; c++) {
                if (c < clen) {
                    for (r = 0, rlen = input[c].length; r < repeatRow - rlen; r++) {
                        input[c].push(input[c][r % rlen]);
                    }
                    input[c].unshift(start.col + c, start.row, 0);
                    instance.spliceCol(...input[c]);
                } else {
                    input[c % clen][0] = start.col + c;
                    instance.spliceCol(...input[c % clen]);
                }
            }
            break;

        case 'shift_right':
            repeatCol = end ? end.col - start.col + 1 : 0;
            repeatRow = end ? end.row - start.row + 1 : 0;
            for (r = 0, rlen = input.length, rmax = Math.max(rlen, repeatRow); r < rmax; r++) {
                if (r < rlen) {
                    for (c = 0, clen = input[r].length; c < repeatCol - clen; c++) {
                        input[r].push(input[r][c % clen]);
                    }
                    input[r].unshift(start.row + r, start.col, 0);
                    instance.spliceRow(...input[r]);
                } else {
                    input[r % rlen][0] = start.row + r;
                    instance.spliceRow(...input[r % rlen]);
                }
            }
            break;

        case 'overwrite':
        default:
            // overwrite and other not specified options
            current.row = start.row;
            current.col = start.col;

            const selected = { // selected range
                row: (end && start) ? (end.row - start.row + 1) : 1,
                col: (end && start) ? (end.col - start.col + 1) : 1
            };
            let skippedRow = 0;
            let skippedColumn = 0;
            let pushData = true;
            let cellMeta;

            const getInputValue = function getInputValue(row, col = null) {
                const rowValue = input[row % input.length];

                if (col !== null) {
                    return rowValue[col % rowValue.length];
                }

                return rowValue;
            };
            const rowInputLength = input.length;
            const rowSelectionLength = end ? end.row - start.row + 1 : 0;

            if (end) {
                rlen = rowSelectionLength;
            } else {
                rlen = Math.max(rowInputLength, rowSelectionLength);
            }
            for (r = 0; r < rlen; r++) {
                if ((end && current.row > end.row && rowSelectionLength > rowInputLength) ||
                    (!priv.settings.allowInsertRow && current.row > instance.countRows() - 1) ||
                    (current.row >= priv.settings.maxRows)) {
                    break;
                }
                const visualRow = r - skippedRow;
                const colInputLength = getInputValue(visualRow).length;
                const colSelectionLength = end ? end.col - start.col + 1 : 0;

                if (end) {
                    clen = colSelectionLength;
                } else {
                    clen = Math.max(colInputLength, colSelectionLength);
                }
                current.col = start.col;
                cellMeta = instance.getCellMeta(current.row, current.col);

                if ((source === 'CopyPaste.paste' || source === 'Autofill.fill') && cellMeta.skipRowOnPaste) {
                    skippedRow += 1;
                    current.row += 1;
                    rlen += 1;
                    continue;
                }
                skippedColumn = 0;

                for (c = 0; c < clen; c++) {
                    if ((end && current.col > end.col && colSelectionLength > colInputLength) ||
                        (!priv.settings.allowInsertColumn && current.col > instance.countCols() - 1) ||
                        (current.col >= priv.settings.maxCols)) {
                        break;
                    }
                    cellMeta = instance.getCellMeta(current.row, current.col);

                    if ((source === 'CopyPaste.paste' || source === 'Autofill.fill') && cellMeta.skipColumnOnPaste) {
                        skippedColumn += 1;
                        current.col += 1;
                        clen += 1;
                        continue;
                    }
                    if (cellMeta.readOnly) {
                        current.col += 1;
                        continue;
                    }
                    const visualColumn = c - skippedColumn;
                    let value = getInputValue(visualRow, visualColumn);
                    let orgValue = instance.getDataAtCell(current.row, current.col);
                    const index = {
                        row: visualRow,
                        col: visualColumn
                    };

                    if (source === 'Autofill.fill') {
                        const result = instance.runHooks('beforeAutofillInsidePopulate', index, direction, input, deltas, {}, selected);

                        if (result) {
                            value = isUndefined(result.value) ? value : result.value;
                        }
                    }
                    if (value !== null && typeof value === 'object') {
                        // when 'value' is array and 'orgValue' is null, set 'orgValue' to
                        // an empty array so that the null value can be compared to 'value'
                        // as an empty value for the array context
                        if (Array.isArray(value) && orgValue === null) orgValue = [];

                        if (orgValue === null || typeof orgValue !== 'object') {
                            pushData = false;

                        } else {
                            const orgValueSchema = duckSchema(Array.isArray(orgValue) ? orgValue : (orgValue[0] || orgValue));
                            const valueSchema = duckSchema(Array.isArray(value) ? value : (value[0] || value));

                            if (isObjectEqual(orgValueSchema, valueSchema)) {
                                value = deepClone(value);
                            } else {
                                pushData = false;
                            }
                        }

                    } else if (orgValue !== null && typeof orgValue === 'object') {
                        pushData = false;
                    }
                    if (pushData) {
                        setData.push([current.row, current.col, value]);
                    }
                    pushData = true;
                    current.col += 1;
                }
                current.row += 1;
            }
            instance.setDataAtCell(setData, null, null, source || 'populateFromArray');
            break;
    }
};*/