<script>
export default {
  inject: ["rformc"],
  props: {
    rform: {},
    name: { type: String },
    width: {},
    required: { type: Boolean },
    readonly: { type: Boolean },
    searchable: { type: Boolean, default: false },
    help: { type: String, default: null },
    searchField: { type: String },
    operation: { type: String },
    autofocus: { type: Boolean },
    noBindField: { type: Boolean },
  },
  data: function () {
    return {
      form: this.rform ? this.rform : this.rformc,
    };
  },
  methods: {
    getName() {
      return this.name.split(".").slice(-1)[0];
    },
    getContext(override = false, errors = false) {
      var self = this;
      var namespaces = self.name.split(".");
      var context = errors ? self.form.errors : self.form.formData;
      if (override) {
        for (let i = 0; i < namespaces.length; i++) {
          if (i == namespaces.length - 1) {
            return context;
          } else if (typeof context[namespaces[i]] == "undefined") {
            let n = namespaces[i + 1];
            if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
              //Número entero positivo
              self.$set(context, namespaces[i], []);
            } else {
              self.$set(context, namespaces[i], {});
            }
          }
          context = context[namespaces[i]];
        }
      } else {
        for (let i = 0; i < namespaces.length - 1; i++) {
          if (typeof context == "undefined") {
            let n = namespaces[i];
            if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
              //Número entero positivo
              context = [];
            } else {
              context = {};
            }
          }
          context = context[namespaces[i]];
        }
        return context || {};
      }
    },
    getValue: function () {
      return this.getContext()[this.getName()];
    },
    setValue: function (val) {
      this.$set(this.getContext(true), this.getName(), val);
    },
    bindFields() {
      var self = this;
      if ((!self.type || self.type != "password") && !self.noBindField)
        self.form.fields.push(self.name);

      if (self.searchable && !self.form.filterConfig[self.name]) {
        self.form.filterConfig[self.name] = {
          searchField: self.searchField || self.primary,
          operation: self.operation || "*LIKE*",
        };
        //window.console.log(self.name, self.form.filterConfig[self.name]);
      }
    },
    removeError() {
      this.$delete(this.getContext(true, true), this.getName());
    },
    onChange: function () {
      var self = this;
      self.removeError();
      if (
        self.searchable &&
        self.form.mode == "search" &&
        self.form.htSettings
      ) {
        self.$nextTick(() => self.form.setFilter(self.form.getFilter()));
      }
    },
    focusNextField: function (el) {
      var self = this;
      let $ = window.$;
      let $parent = $(self.$el).closest(".tab");
      if (!$parent.length) $parent = window.$(self.$el).closest("article");
      let $focusables = self.$utils.misc.getFocusables($parent);
      if (!$focusables.length) return;
      let i = $focusables.index(el);
      if (i == -1) return;
      if (i == $focusables.length - 1) i = -1;
      $focusables[i + 1].focus();
      //window.console.log($focusables[i + 1]);
      if (typeof $focusables[i + 1].select === "function") $focusables[i + 1].select();
    },
    focusPrevField: function (el) {
      var self = this;
      let $ = window.$;
      let $parent = $(self.$el).closest(".tab");
      if (!$parent.length) $parent = window.$(self.$el).closest("article");
      let focusables =
        'input:not([type=hidden]):not([type=file]):not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
      let $focusables = $parent
        .find(focusables)
        .not(".handsontable *")
        .not('[tabindex="-1"]');

      if (!$focusables.length) return;
      let i = $focusables.index(el);
      if (i == -1) return;
      if (i == 0) i = $focusables.length;
      $focusables[i - 1].focus();
      if (typeof $focusables[i - 1].select === "function") $focusables[i - 1].select();
    },
  },
  mounted: function () {
    var self = this;
    self.bindFields();
    if (self.$refs.input) {
      var inputs = Array.isArray(self.$refs.input)
        ? self.$refs.input
        : [self.$refs.input];
      inputs.forEach((el) => {
        [
          "change",
          "input",
          "keyup",
          "keydown",
          "keypress",
          "focus",
          "blur",
        ].forEach((event) =>
          el.addEventListener(event, ($event) =>
            self.$emit(event, $event, "edit")
          )
        );
        el.addEventListener("change", ($event) =>
          self.form.$emit("change", self.name, "edit", $event)
        );
        el.addEventListener("keydown", function (e) {
          if (
            (e.key === "Enter"&&e.target.nodeName!='textarea') ||
            (e.key == "Tab" && !self.form.$refs["main-tabs"])
          ) {
            if (e.shiftKey) self.focusPrevField(e.target);
            else self.focusNextField(e.target);
          }
          if (e.key == "Tab") {
            e.preventDefault();
          }
        });
      });
    }
  },
};
</script>