/* eslint-disable prettier/prettier */
import jQuery from "jquery";
window.$ = jQuery;

var DB = {
    server: "https://api.diplata.com/",//"http://rober8ro.ddns.net/ERP/backend/",//http://localhost/ERP/backend/
    request: window.$.ajax,
    getList: function (model, params) {
        var self = this;
        params = params || {};

        return new Promise(function (resolve, reject) {
            let pars = {
                conditions: params.filter || [],
                offset: params.offset || 0, // (params.page || 1) - 1,
                limit: params.limit || null,
                order: params.order || '',
                orderDir: params.orderDir || "ASC",
                format: params.format || 'standard',
                depth: params.depth ?? 1,
                fields: params.fields || [],
                field: params.field || undefined
            };
            var params_ser = window.$.param(pars);
            self.request({
                url: self.server + model + "/?",
                method: "POST",
                headers: {
                    'X-HTTP-Method-Override': 'GET'
                },
                contentType: "application/json",
                dataType: "json",
                crossDomain: true,
                data: pars
            })
                .then(function (res) {
                    if (res.success) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                })
                .catch(function (xhr) {
                    try {
                        reject(JSON.parse(xhr.response).error);
                    } catch (e) {
                        reject({ msg: xhr.responseText || 'Error de comunicación' });
                    }
                });
        });
    },
    get: function (model, params) {
        var self = this;
        return new Promise(function (resolve, reject) {
            if (!params.itemId) reject({ error: "itemId not defined" });
            //window.console.log(params.itemId, encodeURIComponent(params.itemId));
            self.request({
                url: self.server + model + "/?id=" + encodeURIComponent(params.itemId),
                method: "POST",
                headers: {
                    'X-HTTP-Method-Override': 'GET'
                },
                contentType: "application/json",
                dataType: "json",
                crossDomain: true,
                data: {
                    log: params.log || false,
                    depth: 2,
                    fields: params.fields || [],
                    field: params.field || undefined
                }
            })
                .then(function (res) {
                    if (res.success) {
                        resolve(res.data);
                    } else {
                        reject(res);
                    }
                })
                .catch(function (xhr) {
                    try {
                        reject(JSON.parse(xhr.response).error);
                    } catch (e) {
                        reject({ msg: xhr.responseText || 'Error de comunicación' });
                    }
                });
        });
    },
    save: function (model, params) {
        var self = this;
        if (params.itemId) {
            return self.put(model, params);
        } else {
            return self.post(model, params);
        }
    },
    put: function (model, params) {
        var self = this;
        return new Promise(function (resolve, reject) {
            if (!params.itemId) reject({ error: "itemId not defined" });
            self.request({
                url: self.server + model + "/?id=" + encodeURIComponent(params.itemId),
                method: "PUT",
                contentType: "application/json",
                dataType: "json",
                crossDomain: true,
                data: { data: params.data, log: params.log || false }
            })
                .then(function (res) {
                    if (res.success) {
                        resolve(res);
                        window.App.$emit('db-save', { model: model, id: res.id, data: params.data });
                        window.App.$emit('db-put', { model: model, id: res.id, data: params.data });
                    } else {
                        reject(res);
                    }
                })
                .catch(function (xhr) {
                    try {
                        reject(JSON.parse(xhr.response).error);
                    } catch (e) {
                        reject({ msg: xhr.responseText || 'Error de comunicación' });
                    }
                });
        });
    },
    post: function (model, params) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.request({
                url: self.server + model + "/",
                method: "POST",
                contentType: "application/json",
                dataType: "json",
                crossDomain: true,
                data: { data: params.data, log: params.log || false }
            })
                .then(function (res) {
                    if (res.success) {
                        resolve(res);
                        window.App.$emit('db-save', { model: model, id: res.id, data: params.data });
                        window.App.$emit('db-post', { model: model, id: res.id, data: params.data });
                    } else {
                        reject(res);
                    }
                })
                .catch(function (xhr) {
                    try {
                        var obj = JSON.parse(xhr.response);
                        reject(obj.message ? obj.message : obj.error);
                    } catch (e) {
                        reject({ msg: xhr.responseText || 'Error de comunicación' });
                    }
                });
        });
    },
    delete: function (model, params) {
        var self = this;
        return new Promise(function (resolve, reject) {
            if (!params.itemId) reject({ error: "itemId not defined" });
            self.request({
                url: self.server + model + "/" + encodeURIComponent(params.itemId),
                method: "DELETE",
                contentType: "application/json",
                dataType: "json",
                crossDomain: true,
                data: { ...params.data, ... { log: params.log || false } }
            })
                .then(function (res) {
                    if (res.success) {
                        resolve(res.data);
                        window.App.$emit('db-delete', { model: model, id: res.id });
                    } else {
                        reject(res);
                    }
                })
                .catch(function (xhr) {
                    try {
                        reject(JSON.parse(xhr.response).error);
                    } catch (e) {
                        reject({ msg: xhr.responseText });
                    }
                });
        });
    },
    action: function (model, action, params) {
        var self = this;
        params = params || {};
        return new Promise(function (resolve, reject) {
            self.request({
                url: self.server + model + "/?action=" + action,
                method: 'POST',
                headers: {
                    'X-HTTP-Method-Override': params.method || "GET"
                },
                //contentType: "application/json",
                dataType: "json",
                crossDomain: true,
                data: {
                    ...params.data
                }
            })
                .then(function (res) {
                    if (res.success) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                })
                .catch(function (xhr) {
                    try {
                        reject(JSON.parse(xhr.response).error);
                    } catch (e) {
                        reject({ msg: xhr.responseText || 'Error de comunicación' });
                    }
                });
        });
    },
    getDatabases() {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.request({
                url: self.server + "backend/getDatabases.php",
                method: 'GET',
                //contentType: "application/json",
                dataType: "json",
                crossDomain: true
            })
                .then(function (res) {
                    if (res.success) {
                        resolve(res.data);
                    } else {
                        reject(res);
                    }
                })
                .catch(function (res) {
                    try {
                        reject(JSON.parse(res.response).error);
                    } catch (e) {
                        reject(res.response || 'Error de comunicación');
                    }
                });
        });
    },
    deleteDatabase(db_name) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.request({
                url: self.server + "deleteDatabase.php",
                method: 'POST',
                //contentType: "application/json",
                dataType: "json",
                crossDomain: true,
                data: { db_name }
            })
                .then(function (res) {
                    if (res.success) {
                        resolve(res.data);
                    } else {
                        reject(res);
                    }
                })
                .catch(function (res) {
                    try {
                        reject(JSON.parse(res.response).error);
                    } catch (e) {
                        reject(res.response || 'Error de comunicación');
                    }
                });
        });
    },
    createDatabase(data) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.request({
                url: self.server + "createDatabase.php",
                method: 'POST',
                //contentType: "application/json",
                dataType: "json",
                crossDomain: true,
                data: data
            })
                .then(function (res) {
                    if (res.success) {
                        resolve(res.data);
                    } else {
                        reject(res);
                    }
                })
                .catch(function (res) {
                    try {
                        reject(JSON.parse(res.response).error);
                    } catch (e) {
                        reject(res.response || 'Error de comunicación');
                    }
                });
        });
    }
};
export default DB;