import Vue from 'vue/dist/vue.js';
import App from "./App.vue";
import Login from "./Login.vue";
import DbManagement from "./DbManagement.vue";
import LoginDbManagement from "./LoginDbManagement.vue";

window.FontAwesomeConfig = {
    searchPseudoElements: true
}
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";
Vue.component("fa-icon", FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers)
library.add(fas);
library.add(far);
import VTooltip from "v-tooltip";
Vue.use(VTooltip);
import VueCollapse from "vue2-collapse";
Vue.use(VueCollapse);
import ToggleButton from "vue-js-toggle-button";
Vue.use(ToggleButton);
/*import VueCroppie from "vue-croppie";
import "croppie/croppie.css"; // import the croppie css manually
Vue.use(VueCroppie);*/
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
Vue.component(VueCropper);
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';
Vue.use(VueIziToast, {
    position: 'topCenter',
    timeout: 2000,
    layout: 2,
});
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
var lang = localStorage.getItem("lang") || "es";
import messages from "./translations.js";
const i18n = new VueI18n({
    locale: lang,
    messages
});


import Field from "./components/Field.vue";
Vue.component("field", Field);
import WindowHeader from "./components/WindowHeader.vue";
Vue.component("window-header", WindowHeader);
import NavForm from "./components/NavForm.vue";
Vue.component("nav-form", NavForm);
import rTabs from "./components/rTabs.vue";
Vue.component("r-tabs", rTabs);
import rDropdown from "./components/rDropdown.vue";
Vue.component("r-dropdown", rDropdown);
import rProgressbar from "./components/rProgressbar.vue";
Vue.component("r-progressbar", rProgressbar);

/*const $ = require("jquery");
window.$ = $;*/
import jQuery from "jquery";
window.$ = jQuery;

import "./components/handsontable/date2.js";
import "./components/handsontable/m2m.js";
import "./components/handsontable/m2o.js";
import "./components/handsontable/files.js";
import "./components/handsontable/color.js";
import "./components/handsontable-autocomplete2/autocomplete3.editor.js";

import numbro from "numbro";
import languages from "numbro/dist/languages.min.js";
Object.values(languages).forEach((l) => numbro.registerLanguage(l));

import tiff from "tiff.js";
window.Tiff = tiff;

import VueVirtualScroller from 'vue-virtual-scroller'
Vue.use(VueVirtualScroller)
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'


import DB from "./db.js";
window.DB = DB;

/*UTILS */
import validation from "./utils/validation.js";
import misc from "./utils/misc.js";
var UtilsPlugin = {};
UtilsPlugin.install = function (Vue, options) {
    Vue.prototype.$utils = { validation, misc };
};
Vue.use(UtilsPlugin);

import "./css/style.css";
Vue.config.productionTip = false;


//Routes
import VueRouter from "vue-router";
Vue.use(VueRouter);
const router = new VueRouter({
    //mode: 'history',
    routes: [{
        path: '/',
        name: 'Home',
        component: App,
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('session')) {
                next()
                return;
            }
            next('/login')
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: (to, from, next) => {
            if (!localStorage.getItem('session')) {
                next()
                return;
            }
            next('/')
        }
    },
    {
        path: '/login-db-management',
        name: 'Login DB Management',
        component: LoginDbManagement,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('master');
            next();
        }
    },
    {
        path: '/db-management',
        name: 'DB Management',
        component: DbManagement,
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('master')) {
                next()
                return;
            }
            next('/login-db-management')
        }
    },
    ],
})


window.App = new Vue({
    //render: h => h(App),
    render: h => h('router-view'),
    router,
    i18n
}).$mount("#app");