<template>
  <div class="field item-input" :class="classMain">
    <div class="item-inner">
      <label v-tooltip="help" class="item-title item-label" :for="name">{{label}}</label>
      <div class="item-input-wrap">
        <textarea
          ref="input"
          :value="getValue()"
          @input="setValue($event.target.value)"
          @change="onChange"
          autocomplete="new-password"
          type="text"
          v-on="$listeners"
          :placeholder="placeholder"
          :maxlength="maxlength"
          :style="'width:'+width+';height:'+height+';'+inputStyle"
          :readonly="readonly||form.mode=='view'||form.mode=='empty'||(form.mode=='search'&&!searchable)"
          v-tooltip="form.errors[name]"
        />
      </div>
    </div>
  </div>
</template>
<script>
import FieldMixin from "./FieldMixin.vue";
export default {
  inject: ["rformc"],
  mixins: [FieldMixin],
  props: {
    /*rform: {},
    name: { type: String },
    width: { type: String },
    required: { type: Boolean },
    readonly: { type: Boolean },
    searchable: { type: Boolean, default: false },
    help: {},*/
    label: { type: String },
    "inline-label": { type: Boolean },
    maxlength: { type: Number },
    placeholder: { type: String },
    inputStyle: { type: String },
    image: { type: String },
    height: { type: String }
  },
  computed: {
    classMain: function() {
      var classMain = {};
      classMain["inline-label"] = this.inlineLabel;
      classMain["readonly"] = this.readonly || this.form.readonly;
      classMain["mode-" + this.form.mode] = true;
      classMain["wrong"] = this.name in this.form.errors;
      return classMain;
    }
  },
  data: function() {
    return {
      form: this.rform ? this.rform : this.rformc
    };
  },
  methods: {
    /*getValue: function() {
      var self = this;
      return self.form.formData[self.name];
    },
    setValue: function(val) {
      var self = this;
      self.$set(self.form.formData, self.name, val);
    },
    onChange: function() {
      var self = this;
      if (self.form.mode == "search" && self.form.htSettings) {
        self.form.setFilter(self.form.getFilter());
      }
    }*/
  },
  mounted: function() {
    //window.console.log(this.form);
  }
};
</script>
<style>
textarea {
  resize: none;
  padding: 1px 5px;
  height: auto;
  border-radius: 3px;
  /*border: none !important;
    border-bottom: solid 1px #CCC !important;*/
  border: 1px solid #ccc;
  position: relative;
  font-family: -apple-system, system-ui, Helvetica, Arial, sans-serif;
}
.field.mode-search textarea {
  background-color: #ffffee;
}

.field textarea:read-only {
  /*border-color: #eee;*/
  background-color: #eee;
}

.field textarea:focus {
  background-color: #e5f3f8;
}

.wrong textarea {
  /*border: solid 1px #ff6a6a;*/
  background: rgba(255, 106, 106, 0.2);
}
</style>