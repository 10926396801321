var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field item-input",class:_vm.classMain},[(_vm.imgProp)?_vm._t("media",[_c('div',{staticClass:"item-media"},[(_vm.$utils.misc.rget(_vm.getValue(), _vm.imgProp))?_c('img',{staticStyle:{"height":"34px"},attrs:{"src":_vm.$utils.misc.rget(_vm.getValue(), _vm.imgProp)}}):_c('img',{staticStyle:{"height":"34px"},attrs:{"src":require("./../assets/noimage.png")}})])]):_vm._e(),_c('div',{staticClass:"item-inner"},[_c('label',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.help),expression:"help"}],staticClass:"item-title item-label",attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"item-input-wrap"},[_c('Select2',_vm._g({directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.form.errors[_vm.name]),expression:"form.errors[name]"}],ref:"select",style:('display: flex;width:' + _vm.width),attrs:{"value":_vm.getValue()[_vm.primary],"options":_vm.getOptions(),"settings":_vm.settings,"disabled":(_vm.readonly && _vm.form.mode != 'search') ||
          _vm.form.mode == 'view' ||
          _vm.form.mode == 'empty' ||
          (_vm.form.mode == 'search' && !_vm.searchable)},on:{"select":_vm.onSelect,"change":_vm.onChange}},_vm.$listeners)),(_vm.externalWindow)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Abrir ventana'),expression:"'Abrir ventana'"}],staticClass:"button button-plain",class:{
          disabled:
            (_vm.readonly && _vm.form.mode != 'search') ||
            _vm.form.mode == 'view' ||
            _vm.form.mode == 'empty' ||
            (_vm.form.mode == 'search' && !_vm.searchable),
        },staticStyle:{"padding":"0"},attrs:{"href":"#"},on:{"click":_vm.abrirVentana}},[_c('fa-icon',{attrs:{"icon":"external-link-alt"}})],1):_vm._e()],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }