import Handsontable from 'handsontable'
(function(Handsontable) {
    "use strict";

    /* Editor */

    var Date2Editor = Handsontable.editors.DateEditor.prototype.extend();

    Date2Editor.prototype.init = function() {
        Handsontable.editors.DateEditor.prototype.init.apply(this, arguments);
        this.defaultDateFormat = 'YYYY-MM-DD';
    }

    Date2Editor.prototype.getValue = function() {
        let newValue = this.TEXTAREA.value;
        if (newValue) {
            let arr = newValue.split(/[/.-]+/);
            if (arr.length == 2) arr.push(new Date().getFullYear())
            else if (arr.length == 1) {
                arr.push(new Date().getMonth() + 1)
                arr.push(new Date().getFullYear())
            }
            let d = new Date(arr.reverse().join('-'));
            if (d instanceof Date && !isNaN(d)) {
                //d = new Date(d.getTime() + d.getTimezoneOffset() * 60000); //Corrige 1 día menos
                let padLeft = n => ("00" + n).slice(-2);
                newValue = [d.getFullYear(), padLeft(d.getMonth() + 1), padLeft(d.getDate())].join('-');
            } else {
                newValue = null;
            }
        }
        return newValue; //FORMATO yyyy-mm-dd
    }

    Date2Editor.prototype.setValue = function(newValue) {
        //newValue puede ser dd/mm/yyyy o yyyy-mm-dd
        //Aquí tiene que llegar ya fecha válida o undefined
        if (newValue && newValue.split('-').length > 1) {
            let d = new Date(newValue);
            let padLeft = n => ("00" + n).slice(-2);
            newValue = [padLeft(d.getDate()), padLeft(d.getMonth() + 1), d.getFullYear()].join('/');
        }
        this.TEXTAREA.value = newValue; //FORMATO dd/mm/yyyy
    }

    Handsontable.editors.Date2Editor = Date2Editor;

    Handsontable.editors.registerEditor('date2', Date2Editor);

    /* Renderer */

    var Date2Renderer = function(instance, td, row, column, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        if (value) {
            let d = new Date(value);
            if (d instanceof Date && !isNaN(d)) {
                d = new Date(d.getTime() + d.getTimezoneOffset() * 60000); //Corrige 1 día menos
                let padLeft = n => ("00" + n).slice(-2);
                value = [padLeft(d.getDate()),
                    padLeft(d.getMonth() + 1),
                    d.getFullYear()
                ].join('/');
            }
        }

        td.innerText = value;
    }



    Handsontable.renderers.Date2Renderer = Date2Renderer;

    Handsontable.renderers.registerRenderer('date2', Date2Renderer);

    /* CELLTYPE */

    Handsontable.cellTypes.registerCellType('date2', {
        editor: Handsontable.editors.Date2Editor,
        renderer: Handsontable.renderers.Date2Renderer
    });

})(Handsontable);