<template>
  <div
    class="image-container"
    :class="{ wrong: name in form.errors }"
    v-tooltip="form.errors[name]"
  >
    <label
      v-if="label"
      v-tooltip="help"
      v-html="label"
      style="position: absolute; top: -15px"
    ></label>
    <template
      v-if="
        readonly ||
        form.mode == 'view' ||
        form.mode == 'empty' ||
        (form.mode == 'search' && !searchable)
      "
    >
      <img v-if="getValue()" :src="getValue()" width="100%" height="100%" />
    </template>
    <template v-else>
      <img-cropper
        class="img-cropper"
        ref="cropper"
        :src="getValue() || ''"
        @change="onChangeCropper"
        :width="$el.clientWidth"
        :height="$el.clientHeight"
      ></img-cropper>
      <div
        v-if="getValue()"
        class="removeImage"
        @click="
          setValue(null);
          onChange();
        "
      >
        <fa-icon icon="trash" size="xs" style="color: #444" />
      </div>
      <div class="cropControls">
        <fa-icon
          @click="selectImage()"
          icon="image"
          size="1x"
          style="color: white"
          v-tooltip="$t('rimage.seleccionar_imagen')"
        />
        <fa-icon
          @click="rotate(-90)"
          icon="undo"
          size="xs"
          style="color: white"
          :class="{ disabled: !getValue() }"
          v-tooltip="$t('rimage.rotar_izquierda')"
        />
        <fa-icon
          @click="rotate(90)"
          icon="redo"
          size="xs"
          style="color: white"
          :class="{ disabled: !getValue() }"
          v-tooltip="$t('rimage.rotar_derecha')"
        />
      </div>
    </template>
    <input ref="inputFile" type="file" accept="image/*" style="display: none" />
  </div>
</template>
<style>
.removeImage {
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 50%;
  background: white;
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cropControls {
  padding: 2px;
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: space-evenly;
  align-items: center;
}
.cropControls > * {
  cursor: pointer;
}

.cropControls > *.disabled {
  cursor: initial;
  opacity: 0.2;
}

.image-container:hover .cropControls {
  display: flex;
}
.image-container {
  background: url(./../assets/noimage.png);
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
}
.image-container.wrong {
  border: solid 1px #ff6a6a;
}
.image-container img {
  background: white;
}
.img-cropper img[src=""] {
  display: none;
}
</style>
<script>
import ImgCropper from "./imgCropper.vue";
import FieldMixin from "./FieldMixin.vue";
export default {
  inject: ["rformc"],
  components: { ImgCropper },
  mixins: [FieldMixin],
  data: function () {
    return {
      form: this.rform ? this.rform : this.rformc,
      options: null,
      loading: true,
      self: this,
    };
  },
  props: {
    label: { type: String },
  },
  mounted: function () {
    var self = this;

    var reader = new FileReader();
    reader.onload = function (e) {
      self.loading = true;
      let file = self.$refs.inputFile.files[0];
      self.$refs.inputFile.value = null;
      if (file.type === "image/tiff") {
        let input = new window.Tiff({ buffer: reader.result });
        let canvas = input.toCanvas();
        var dataURL = canvas.toDataURL();
      } else {
        dataURL = reader.result;
      }
      //self.setValue(e.target.result);
      try {
        self.$refs.cropper.setImg(dataURL);
      } catch (e) {
        window.console.log(e);
        alert(e);
      }
    };
    /*$imgcont.find(".openImage").click(function() {
      $imgcont.find('input[type="file"]').click();
    });*/
    window.$(self.$refs.inputFile).change(function () {
      var input = this;
      if (input.files && input.files[0]) {
        let file = input.files[0];
        if (file.type === "image/tiff") {
          return reader.readAsArrayBuffer(file);
        } else {
          return reader.readAsDataURL(file);
        }
      }
    });
  },
  methods: {
    /*setValue: function(val) {
      var self = this;
      self.$set(self.form.formData, self.name, val);
    },*/
    onChangeCropper(val) {
      var self = this;
      self.setValue(val);
      self.onChange();
    },
    selectImage() {
      var self = this;
      window.$(self.$refs.inputFile).click();
    },
    rotate(rotationAngle) {
      this.$refs.cropper.rotate(rotationAngle);
    },
  },
};
</script>
