<template>
  <div
    class="field item-input"
    :class="classMain"
  >
    <slot
      v-if="imgProp"
      name="media"
    >
      <div class="item-media">
        <img
          v-if="$utils.misc.rget(getValue(), imgProp)"
          :src="$utils.misc.rget(getValue(), imgProp)"
          style="height: 34px"
        />
        <img
          v-else
          src="./../assets/noimage.png"
          style="height: 34px"
        />
      </div>
    </slot>
    <div class="item-inner">
      <label
        v-tooltip="help"
        class="item-title item-label"
        :for="name"
      >{{
      label
      }}</label>
      <div class="item-input-wrap">
        <Select2
          ref="select"
          v-tooltip="form.errors[name]"
          :value="getValue()[primary]"
          :options="getOptions()"
          :settings="settings"
          :style="'display: flex;width:' + width"
          @select="onSelect"
          @change="onChange"
          v-on="$listeners"
          :disabled="
            (readonly && form.mode != 'search') ||
            form.mode == 'view' ||
            form.mode == 'empty' ||
            (form.mode == 'search' && !searchable)
          "
        />
        <a
          v-if="externalWindow"
          href="#"
          v-tooltip="'Abrir ventana'"
          class="button button-plain"
          style="padding: 0"
          :class="{
            disabled:
              (readonly && form.mode != 'search') ||
              form.mode == 'view' ||
              form.mode == 'empty' ||
              (form.mode == 'search' && !searchable),
          }"
          @click="abrirVentana"
        >
          <fa-icon icon="external-link-alt" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import "select2/dist/js/select2.full";
import Select2 from "v-select2-component";
import FieldMixin from "./FieldMixin.vue";

var time = null;

export default {
  components: { Select2 },
  inject: ["rformc", "app"],
  mixins: [FieldMixin],
  props: {
    /*rform: {},
    name: { type: String },
    width: { type: String },
    required: { type: Boolean },
    readonly: { type: Boolean },
    searchable: { type: Boolean, default: false },
    help: {}*/
    label: { type: String },
    "inline-label": { type: Boolean },
    maxlength: { type: Number },
    placeholder: { type: String },
    inputStyle: { type: String },

    primary: { type: String, default: "codigo" },
    labelProp: { type: String, default: "nombre" },
    searchProp: { type: String },
    imgProp: { type: String },
    templateSelection: {
      type: Function,
    },
    templateResult: {
      type: Function,
    },
    dbAdapter: {
      type: String,
    },
    fields: { type: Array, default: () => [] },
    filter: { type: Array, default: () => [] },
    externalWindow: { type: String },
  },
  computed: {
    classMain: function () {
      var classMain = {};
      classMain["inline-label"] = this.inlineLabel;
      classMain["readonly"] = this.readonly || this.form.readonly;
      classMain["mode-" + this.form.mode] = true;
      classMain["wrong"] = this.name in this.form.errors;
      classMain["autofocus"] = this.autofocus;
      return classMain;
    },
  },
  data: function () {
    var self = this;
    return {
      isLoading: false,
      form: this.rform ? this.rform : this.rformc,
      settings: {
        allowClear: true,
        placeholder: {
          id: "",
          [self.labelProp]: self.placeholder,
        },
        templateResult:
          self.templateResult ||
          function (item) {
            if (!item[self.primary]) return item[self.labelProp];
            return window.$(
              '<span style="display:flex"><span class="codelabel">' +
              item[self.primary] +
              "</span> " +
              item[self.labelProp] +
              "</span>"
            );
          },
        templateSelection:
          self.templateSelection ||
          function (item) {
            if (!item[self.primary]) return item[self.labelProp];
            return window.$(
              '<span style="display:flex"><span  class="codelabel">' +
              item[self.primary] +
              "</span> " +
              item[self.labelProp] +
              "</span>"
            );
          },
        ajax: {
          data(params) {
            var fields = [];
            fields.push(self.labelProp);
            if (self.imgProp) {
              //fields.push(self.imgProp.split(".")[0]); //TODO optimizar como en handsontable
              let ar = self.imgProp.split(".");
              let ctx = fields;
              for (let i = 0; i < ar.length; i++) {
                if (i == ar.length - 1) {
                  ctx.push(ar[i]);
                } else {
                  ctx.push({
                    name: ar[i],
                    fields: [],
                  });
                  ctx = ctx[ctx.length - 1].fields;
                }
              }
            }
            fields = [...fields, ...self.fields];
            let filter = [
              "OR",
              [self.searchProp || self.labelProp, "%LIKE%", params.term],
              [self.primary, "%LIKE%", params.term],
            ];
            if (self.filter?.length) filter = [...[filter], ...[self.filter]];
            let res = {
              filter,
              fields,
              depth: 2,
              limit: 60,
              order: self.primary,
              orderDir: "ASC",
            };
            if (!self.dbAdapter) res.field = self.name;
            return res;
          },
          transport: function (params, success, failure) {
            time = new Date().getTime();
            let time2 = new Date().getTime();
            window.DB.getList(
              self.dbAdapter || self.form.dbAdapter,
              params.data
            ).then(function (res) {
              if (time != time2) failure();
              self.isLoading = false;
              var results = [];
              res.data.forEach(function (item) {
                if (item[self.primary])
                  results.push({ ...item, ...{ id: item[self.primary] } });
              });
              success({
                results: results,
              });
            });
          },
        },
        language: {
          loadingMore: function () {
            return "Cargando...";
          },
          noResults: function () {
            return "Sin resultados";
          },
          searching: function () {
            return "Buscando...";
          },
        },
      },
    };
  },
  methods: {
    abrirVentana() {
      var self = this;
      let v = this.getValue();
      this.app.openWindow(self.externalWindow, {
        itemId: v ? v[this.primary] : null,
      });
    },
    getOptions() {
      var self = this;
      var val = self.getValue();
      if (typeof val === "object" && val && Object.keys(val).length) {
        return [{ ...val, ...{ id: val[self.primary] } }];
      }
      return [];
    },
    onSelect(e) {
      var self = this;
      if (!e.selected) {
        self.setValue(null);
        self.$emit("change", "edit");
        self.$emit("unselect", "edit");
      } else {
        delete e.selected;
        self.setValue(e);
        self.$emit("change", "edit");
        self.$emit("select", "edit");
      }
      self.form.$emit("change", self.name, "edit", e);
      setTimeout(function () {
        self.focusNextField(window.$(self.$el).find("[tabindex=0]")[0]);
      }, 0);
    },
    getValue: function () {
      return this.getContext()[this.getName()] || {};
    },
    bindFields() {
      var self = this;
      if (self.noBindField) return;
      var fields = [self.primary, self.labelProp];
      if (self.imgProp) {
        //fields.push(self.imgProp.split(".")[0]); //TODO optimizar como en handsontable
        let ar = self.imgProp.split(".");
        let ctx = fields;
        for (let i = 0; i < ar.length; i++) {
          if (i == ar.length - 1) {
            ctx.push(ar[i]);
          } else {
            ctx.push({
              name: ar[i],
              fields: [],
            });
            ctx = ctx[ctx.length - 1].fields;
          }
        }
      }
      fields = [...fields, ...self.fields];
      //TODO: fields
      /*fields.push({
          name: self.imgProp,
          fields: ["imagen"]
        });*/
      self.form.fields.push({
        name: self.name,
        fields,
      });
      self.form.filterConfig[self.name] = {
        searchField: self.primary,
        operation: "=",
      };
    },
  },
  mounted: function () {
    //window.console.log(this.form);
    var self = this;

    var abre = false;
    self.$refs.select.$el.addEventListener("keydown", function (e) {
      if (e.key === " ") {
        abre = true;
        self.$refs.select.select2.select2("open");
      }
      if (e.key === "Enter") {
        if (e.shiftKey) self.focusPrevField(e.target);
        else self.focusNextField(e.target);
      }
    });
    window.$(self.$el).on("click", ".select2", function (e) {
      abre = true;
      self.$refs.select.select2.select2("open");
    });

    window.$(self.$el).on("select2:opening", function (e) {
      if (abre) {
        abre = false;
        return true;
      }
      abre = false;
      return false;
    });

    /*self.$refs.select.$el.addEventListener("keydown", function(e) {
      window.console.log(e.target);
      setTimeout(function() {
        window.$(self.$el).click();
        e.preventDefault();
        if (e.key === "Enter") {
          self.focusNextField(e.target);
          window.console.log(self.$refs.select);
          //self.$refs.select.select2("close");
        }
      }, 1000);
    });*/
  },
};
</script>
<style>
/* rAutocomplete */
select {
  width: 100%;
}

/*
.field {
  position: absolute;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

label {
  color: #999;
  font-size: 11px !important;
}

.item-input .item-inner {
  display: flex;
  flex-direction: column;
  align-items: flºex-start;
}
.inline-label.item-input .item-inner {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.item-media {
  margin: 3px;
}
.inline-label .item-label + .item-input-wrap {
  margin-left: 8px;
  display: inline-block;
}*/

.multiselect {
  padding: 1px 3px !important;
  height: auto;
  border-radius: 3px !important;
  /*border: none !important;
    border-bottom: solid 1px #CCC !important;*/
  border: 1px solid #ccc;
  position: relative;
  z-index: 99;
  background-color: white;
  box-sizing: border-box !important;
}

.multiselect input {
  border: none;
  margin: 0;
  padding: 0;
}

.field.mode-search .select2-selection {
  background-color: #ffffee;
}

/**********/
/* SELECT 2 */

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 3px !important;
}

/*.select2-container .select2-choice {
  height: 29px;
}*/

.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container .select2-selection--single {
  height: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 1.3;
  padding-left: 2px;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: rgba(0, 140, 186, 0.1);
  color: black;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #f7f7f7;
}

.select2-results__option {
  padding: 4px;
  line-height: 1;
}

.select2-search--dropdown .select2-search__field {
  padding: 3px;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  /*background: none;
  border-color: transparent;*/
  background-color: #eee !important;
  /*border-color: #ccc;*/
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #ccc;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__arrow {
  display: none;
}

.codelabel {
  border-radius: 3px;
  padding: 0px 4px;
  margin: 2px;
  background-color: #eee;
  font-size: 0.8em;
}

.wrong .select2-container--default .select2-selection--single {
  /*border: solid 1px #ff6a6a;*/
  background: var(--color-wrong);
}
</style>
