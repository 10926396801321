<template>
  <div id="app" @click="onClick" :class="{ bloqueado }">
    <suspend-window v-if="bloqueado"></suspend-window>
    <menutop></menutop>
    <menubar></menubar>
    <menuleft ref="menuleft"></menuleft>
    <main id="desktop">
      <div id="desktop-inner" ref="desktop-inner">
        <div id="desktop-windows">
          <template v-for="(win, iw) in windows">
            <window :win="win" :winidx="iw" :key="'window-' + win.id" ref="windows" :winParentList="windows"></window>
          </template>
        </div>

        <grid :editarWidgets="editarWidgets"></grid>
      </div>

      <div id="desktop-footer">
        <template v-for="(win, iw) in windows">
          <div :key="'minimized-window-' + win.id" class="minimized-window" @click="
            win.minimized = false;
          windows.push(windows[iw]);
          windows.splice(iw, 1);
          " v-if="win.minimized == true">
            <div class="title">{{ win.title }}</div>
          </div>
        </template>
      </div>
    </main>
  </div>
</template>

<script>
import Menubar from "./components/Menubar.vue";
import Menutop from "./components/Menutop.vue";
import Menuleft from "./components/Menuleft.vue";
import Window from "./components/Window.vue";
import SuspendWindow from "./components/SuspendWindow.vue";
import Grid from "./components/grid.vue";
//import Vue from "vue";
export default {
  name: "app",
  components: {
    Menubar,
    Menutop,
    Menuleft,
    Window,
    SuspendWindow,
    Grid,
  },
  data: function () {
    let urlGeneradorInformes = 'https://api.diplata.com/plantilla_informe/?db_name=aureolid_asfas4&action=print';
    //'https://diplata.com/generador_informes/';
    //let urlGeneradorInformes = 'https://rober8ro.ddns.net/ERP/informes/www/';    
    return {
      self: this,
      bloqueado: false,
      windows: [],
      session: {},
      config: {},
      empresa: {},
      usuario: {},
      enlaces: [],
      widgets: [],
      editarWidgets: false,
      urlGeneradorInformes,
      /*urlGeneradorInformesDocumentoEditor: urlGeneradorInformes + 'editor.php',
      urlGeneradorInformesDocumentoPrint: urlGeneradorInformes + 'print_m.php',
      urlGeneradorInformesListadoEditor: urlGeneradorInformes + 'editor_listado.php',
      urlGeneradorInformesListadoPrint: urlGeneradorInformes + 'print_listado.php',
      urlGeneradorInformesEtiquetaEditor: urlGeneradorInformes + 'editor_tags.php',
      urlGeneradorInformesEtiquetaPrint: urlGeneradorInformes + 'print_tags.php',*/
      urlGeneradorInformesDocumentoPrint: 'https://api.diplata.com/plantilla_informe/?db_name=aureolid_asfas4&action=print',
      urlGeneradorInformesDocumentoEditor: 'https://erp.diplata.com/informes/#!/informe/',
      urlGeneradorInformesEtiquetaPrint: 'https://api.diplata.com/plantilla_etiqueta/?db_name=aureolid_asfas4&action=print',
      urlGeneradorInformesEtiquetaEditor: 'https://erp.diplata.com/informes/#!/etiqueta/',
    };
  },
  computed: {},
  methods: {
    logout() {
      localStorage.removeItem("session");
      delete window.$.ajaxSettings.headers["X-Oauth1-Authorization"];
      this.$router.push("/login");
    },
    uid: function () {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    },
    onClick(e) {
      var model = e.target.getAttribute("open-window");
      if (!model) return;
      this.openWindow(model);
    },
    openWindow: function (window_name, opts) {
      var self = this;
      var app = this;
      // TODO catch error
      return import("./windows/" + window_name + ".vue").then(function (res) {
        app.windows.push({
          id: self.uid(),
          window_name: window_name,
          minimized: false,
          children: [],
          //component: () => import("./windows/" + window_name + ".vue")
          component: res.default,
          opts,
        });
      });
    },
    openChildWindow: function (window_name, parent, opts) {
      var self = this;
      var app = this;
      // TODO catch error
      //return import("./windows/" + window_name + ".vue").then(function(res) {
      parent.children.push({
        id: self.uid(),
        window_name: window_name,
        minimized: false,
        children: [],
        component: () => import("./windows/" + window_name + ".vue"),
        //component: res.default,
        opts,
      });
      //});
    },
    onFocus: function () { },
    toast: function (s, type, opts = {}) {
      if (!["success", "error", "info"].includes(type)) type = "info";
      this.$iziToast[type](s, opts.title || "", {
        timeout: opts.duration || 5000,
      });
      if (parseInt(this.config.sonidos)) this.playSound(type);
    },
    confirm(q, successCallback, cancelCallback, inputs = []) {
      //window.console.log(q);
      this.$iziToast.question(q, "", {
        close: false,
        timeout: false,
        overlay: true,
        overlayColor: "rgba(0,0,0,0.1)",
        displayMode: "once",
        inputs,
        buttons: [
          [
            "<button><b>Sí</b></button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button");
              successCallback();
            },
            true,
          ],
          [
            "<button>No</button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button");
              if (cancelCallback) cancelCallback();
            },
          ],
        ],
      });
      /*if (window.confirm(q)) {
        successCallback();
      } else {
        if (cancelCallback) cancelCallback();
      }*/
    },
    playSound: function (type) {
      if (this.config && !parseInt(this.config.sonidos)) return;
      if (!["success", "error", "info"].includes(type)) type = "info";
      let audio = new Audio();
      switch (type) {
        case "success":
          audio.src = require("./assets/success.mp3");
          break;
        case "info":
          audio.src = require("./assets/success.mp3");
          break;
        case "error":
          audio.src = require("./assets/error.mp3");
          break;
        default:
          audio.src = require("./assets/error.mp3");
          break;
      }
      audio.play();
    },
    startSession() {
      var self = this;
      var session = localStorage.getItem("session");
      if (typeof session != "undefined" && session) {
        var sessionObj = JSON.parse(session);
        window.$.ajaxSetup({
          headers: {
            "X-Oauth1-Authorization": sessionObj.token,
          },
        });
        self.session = sessionObj;
        window.session = sessionObj;
        return this.loadGlobals();
      } else return Promise.resolve();
    },
    loadGlobals() {
      var self = this;
      window.DB.get("configuracion", { itemId: 1 })
        .then((res) => (self.config = res))
        .then(
          window.DB.get("empresa", { itemId: 1 }).then(
            (res) => (self.empresa = res)
          )
        )
        .then(
          window.DB.get("usuario", { itemId: self.session.uid }).then(
            (res) => (self.usuario = res)
          )
        )
        .then(
          window.DB.getList("enlace").then((res) => (self.enlaces = res.data))
        )
        .then(() => {
          window.DB.getList("widget", { filter: ["activo", "=", 1] }).then(
            (res) => {
              self.widgets = res.data.map((x) => ({
                ...x,
                i: x.id,
                props: x.props ? JSON.parse(x.props) : {},
                h: parseInt(x.h),
                w: parseInt(x.w),
                x: parseInt(x.x),
                y: parseInt(x.y),
              }));
            }
          );
        });
    },
    guardarWidgets() {
      var self = this;
      let p = Promise.resolve();
      self.widgets.forEach(function (item, i) {
        p = p.then(
          window.DB.put("widget", {
            itemId: item.id,
            data: { x: item.x, y: item.y },
          })
        );
      });
      return p;
    },
  },
  mounted: function () {
    var self = this;

    self.startSession();

    // SUSPENSION
    var t = 0;
    var timer = setInterval(function () {
      t = t + 1;
      if (t > parseInt(self.config.tiempo_suspension) * 60)
        self.bloqueado = true;
    }, 1000);
    window.$(document).mousemove(function (event) {
      t = 0;
    });
    window.$(document).keypress(function (event) {
      t = 0;
    });

    window.$(this.$el).on("click", "a", function (e) {
      if (window.$(this).hasClass("external")) {
        var link =
          '<body style="margin:0"><iframe src="' +
          this.href +
          '" frameborder="0" style="margin:0; padding:0; border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe></body>';
        var win = window.open();
        win.document.write(link);
        e.preventDefault();
        return false;
      }
    });
    let readyToClose = false;
    document.onkeydown = function (e) {
      if (self.bloqueado) return;
      let key = e.key || '';
      if (self.config.atajo_menu === key.toUpperCase() && e.ctrlKey) {
        self.$refs.menuleft.toggle();
        e.preventDefault();
        e.stopPropagation();
      }
      if (!self.windows.length) return;
      let c = e.keyCode || e.which;
      // Active Window
      let win = self.windows[self.windows.length - 1];
      let win2 = self.windows[self.windows.length - 1];
      while (win2.children && win2.children.length) {
        win2 = win2.children[win2.children.length - 1];
      }
      if (c == 27) {
        //ESC
        if (win.instance.$refs["main-collapse"]) {
          if (e.noAbrirAcordeon) return;
          win.instance.$refs["main-collapse"].toggle();
        }
        // Doble ESC
        win = win2 || win;
        if (readyToClose) {
          if (
            (win.instance.mode == "new" || win.instance.mode == "edit") &&
            JSON.stringify(win.instance.lastFormData) !=
            JSON.stringify(win.instance.formData)
          ) {
            self.confirm("¿Seguro que desea cerrar la ventana?", function () {
              win.instance.$emit("close");
            });
          } else {
            win.instance.$emit("close");
          }
        } else {
          readyToClose = true;
          setTimeout(() => {
            readyToClose = false;
          }, 200);
        }
      }
      if (
        self.config.atajo_anadir === key.toUpperCase() &&
        e.ctrlKey &&
        win.instance.atajo_anadir
      ) {
        //window.console.log(win);
        if (!win.instance.$children?.[1]?.nuevo) return;
        win.instance.setMode("new");
        e.preventDefault();
        e.stopPropagation();
      }
      if (self.config.atajo_guardar === key.toUpperCase() && e.ctrlKey) {
        win.instance.saveItem();
        e.preventDefault();
        e.stopPropagation();
      }
      if (
        self.config.atajo_modificar === key.toUpperCase() &&
        e.ctrlKey &&
        win.instance.atajo_modificar
      ) {
        win.instance.setMode("edit");
        e.preventDefault();
        e.stopPropagation();
      }
      if (self.config.atajo_minimizar === key.toUpperCase() && e.ctrlKey) {
        win.instance.$emit("minimize");
        e.preventDefault();
        e.stopPropagation();
      }
      if (
        self.config.atajo_buscar === key.toUpperCase() &&
        e.ctrlKey &&
        win.instance.atajo_buscar
      ) {
        win.instance.setMode("search");
        e.preventDefault();
        e.stopPropagation();
      }
      if (self.config.atajo_cancelar === key.toUpperCase() && e.ctrlKey) {
        win.instance.cancelMode();
        e.preventDefault();
        e.stopPropagation();
      }
    };

    //Websocket

    const console = window.console;
    const openSocket = (wsURL, waitTimer, waitSeed, multiplier) => {
      window.ws = new WebSocket(wsURL);
      let ws = window.ws;
      console.log(`trying to connect to: ${ws.url}`);

      ws.onopen = () => {
        console.log(`connection open to: ${ws.url}`);
        waitTimer = waitSeed; //reset the waitTimer if the connection is made

        ws.onclose = () => {
          console.log(`connection closed to: ${ws.url}`);
          openSocket(ws.url, waitTimer, waitSeed, multiplier);
        };

        ws.onmessage = (e) => {
          //console.log('message', e)
          try {
            let message = JSON.parse(e.data);
            window.App.$emit(message.name, message.data)
          } catch (e) {
            console.error(e)
          }
        };
      };

      ws.onerror = () => {
        //increaese the wait timer if not connected, but stop at a max of 2n-1 the check time
        if (waitTimer < 60000) waitTimer = waitTimer * multiplier;
        console.log(`error opening connection ${ws.url}, next attemp in : ${waitTimer / 1000} seconds`);
        setTimeout(() => { openSocket(ws.url, waitTimer, waitSeed, multiplier) }, waitTimer);
      }
    }
    openSocket('wss://api.diplata.com/wss', 1000, 250, 1.5);

    /*ws.send(JSON.stringify({
      name: 'import-pedido',
      data: {}
    }))*/

    setTimeout(() => {
      window.App.$on('notification', params => {
        self.toast(params.msg, "info");
      })
    }, 0);

  },
  provide: function () {
    return { app: this };
  },
};
</script>
<style>
#app {
  background-color: #f3f3f3;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#desktop {
  margin-left: 0px;
  width: 100%;
  height: calc(100%);
  /*padding-top:38px;*/
  padding-top: 64px;
  padding-bottom: 38px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s ease-in;
}

#desktop-inner {
  width: 100%;
  height: 100%;
  position: relative;

  /*display: flex;
  align-items: flex-end;*/
}

#desktop-windows {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#desktop-footer {
  background: #eee;
  border-top: 1px solid #ccc;
  height: 38px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 5px;
}

.minimized-window {
  background: white;
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin: 0px 2px;
  width: 200px;
  cursor: pointer;
  height: 100%;
}

.minimized-window:hover {
  background: #ddd;
}

#desktop-footer #left-panel.fijo+#desktop {
  margin-left: 220px;
  width: calc(100% - 220px);
}

.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.bloqueado>*:not(.suspend-window) {
  filter: blur(10px);
}
</style>
