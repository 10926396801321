<template>
  <div class="dropdown" :class="{active}" @click="active=!active;">
    <slot name="default"></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: false
    };
  }
};
//@click="dropdownAccionesActive=!dropdownAccionesActive"
</script>
<style>
.dropdown ul {
  position: absolute;
  list-style-type: none;
  display: none;
  top: 100%;
  left:0;
  width: max-content;
  border: solid 1px #ddd;
  margin: 0;
  background: white;
  z-index: 999;
  color: #444;
  text-align: left;
}
.dropdown.active ul {
  display: block;
}

.dropdown.button {
  overflow: visible;
}
</style>
