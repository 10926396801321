export default {
    $: window.$,
    rget: function (obj, name) {
        let namespaces = name.split(".");
        let context = obj;
        for (let i = 0; i < namespaces.length; i++) {
            if (typeof context != "object" || !context) {
                return undefined;
            }
            context = context[namespaces[i]];
        }
        return context;
    },
    formatDate(value) {
        let padLeft = function (n) {
            return ("00" + n).slice(-2);
        };
        let d = new Date(value);
        value = [
            padLeft(d.getDate()),
            padLeft(d.getMonth() + 1),
            d.getFullYear()
        ].join("/");
        return value;
    },
    formatNumber: function (num, dec, suffix = '') {
        if (typeof num != 'string' && typeof num != 'number') return '';
        num = num.toString();
        return (parseFloat((num || "").replace(",", ".")) || 0)
            .toFixed(dec || 2)
            .replace(".", ",")
            .replace(/\B(?<!,\d*)(?=(\d{3})+(?!\d))/g, ".") + suffix;
    },
    getFocusables($parent) {
        let focusables =
            'input:not([type=hidden]):not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
        return $parent
            .find(focusables)
            .not(".handsontable *")
            .not(".select2-search__field")
            .not('[tabindex="-1"]');
    },
    clone: function (obj) {
        function clone(obj) {
            var copy;

            // Handle the 3 simple types, and null or undefined
            if (null == obj || "object" != typeof obj) return obj;

            // Handle Date
            if (obj instanceof Date) {
                copy = new Date();
                copy.setTime(obj.getTime());
                return copy;
            }

            // Handle Array
            if (obj instanceof Array) {
                copy = [];
                for (var i = 0, len = obj.length; i < len; i++) {
                    copy[i] = clone(obj[i]);
                }
                return copy;
            }

            // Handle Object
            if (obj instanceof Object) {
                copy = {};
                for (var attr in obj) {
                    if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
                }
                return copy;
            }

            throw new Error("Unable to copy obj! Its type isn't supported.");
        }
        return clone(obj);
    },
    debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    },
    downloadFile(url, name = 'file') {
        fetch(url + '&token=' + window.session.token)
            .then(resp => {
                if (!resp.ok) {
                    throw new Error("Not 2xx response")
                } else {
                    return resp.blob();
                }
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = name;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(() => alert('Error de descarga'));
    },
    filterPropsInObject(obj, arr) {
        if (!obj) return obj;
        if (typeof arr == 'string') arr = [arr];
        return Object.fromEntries(Object.entries(obj).filter(([key]) => arr.some(x => x == key)));
    }
}

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();
    return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('-');
};

Date.prototype.yyyymmddhhiiss = function () {
    let addZero = (i) => i < 10 ? "0" + i : i;
    return [this.getFullYear(),
    addZero(this.getMonth() + 1),
    addZero(this.getDate())
    ].join('-') + ' ' + [addZero(this.getHours()), addZero(this.getMinutes()), addZero(this.getSeconds())].join(':');
};

Date.prototype.ddmmyyyyhhiiss = function () {
    let addZero = (i) => i < 10 ? "0" + i : i;
    return [addZero(this.getDate()), addZero(this.getMonth() + 1), this.getFullYear()].join('/') +
        ' ' + [addZero(this.getHours()), addZero(this.getMinutes()), addZero(this.getSeconds())].join(':');
};

Date.prototype.ddmmyyyy = function () {
    let addZero = (i) => i < 10 ? "0" + i : i;
    return [addZero(this.getDate()), addZero(this.getMonth() + 1), this.getFullYear()].join('/');
};

// Fix toFixed  
const oldToFixed = Number.prototype.toFixed;
Number.prototype.oldToFixed = oldToFixed;
Number.prototype.toFixed = function (d) {
    let aux = parseFloat(Math.round(oldToFixed.apply(this, [d + 1]) + 'e' + d) + 'e-' + d);
    return aux.oldToFixed(d);
};

Number.prototype.toFixedNumber = function (d) {
    return parseFloat(Math.round(oldToFixed.apply(this, [d + 1]) + 'e' + d) + 'e-' + d);
};

/*Array.prototype.rget = function(name, def = undefined) {
    let namespaces = name.split(".");
    let context = this;
    for (let i = 0; i < namespaces.length - 1; i++) {
        if (typeof context != "object") {
            return def;
        }
        context = context[namespaces[i]];
    }
    return context[namespaces[namespaces.length - 1]];
}

Object.__proto__.rget = function(name, def = undefined) {
    let namespaces = name.split(".");
    let context = this;
    for (let i = 0; i < namespaces.length - 1; i++) {
        if (typeof context != "object") {
            return def;
        }
        context = context[namespaces[i]];
    }
    return context[namespaces[namespaces.length - 1]];
}*/