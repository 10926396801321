<template>
  <nav>
    <slot name="before"></slot>
    <button
      v-if="nuevo && (rform.mode == 'view' || rform.mode == 'empty' || rform.mode == 'search')"
      class="button button-fill"
      @click="rform.setMode('new')"
      v-tooltip="onlyIcons && $t('rform.new')"
    >
      <fa-icon :icon="['far', 'file']" />
      <span v-if="!onlyIcons">{{ $t('rform.new') }}</span>
    </button>
    <button
      v-if="rform.mode == 'edit' || rform.mode == 'new'"
      class="button button-fill"
      @click="rform.saveItem();"
      v-tooltip="onlyIcons && $t('rform.save')"
    >
      <fa-icon icon="save" />
      <span v-if="!onlyIcons">{{ $t('rform.save') }}</span>
    </button>
    <button
      v-if="editar && rform.mode == 'view'"
      class="button button-fill"
      @click="rform.setMode('edit')"
      v-tooltip="onlyIcons && $t('rform.edit')"
    >
      <fa-icon icon="edit" />
      <span v-if="!onlyIcons">{{ $t('rform.edit') }}</span>
    </button>
    <button
      v-if="cancelar && (rform.mode == 'new' || rform.mode == 'edit' || rform.mode == 'search')"
      class="button button-fill"
      @click="rform.cancelMode()"
      v-tooltip="onlyIcons && $t('rform.cancel')"
    >
      <fa-icon icon="times" />
      <span v-if="!onlyIcons">{{ $t('rform.cancel') }}</span>
    </button>
    <button
      v-if="eliminar && rform.mode == 'view'"
      class="button button-fill"
      @click="rform.deleteItem()"
      v-tooltip="onlyIcons && $t('rform.delete')"
    >
      <fa-icon icon="trash" />
      <span v-if="!onlyIcons">{{ $t('rform.delete') }}</span>
    </button>
    <button
      v-if="buscar && (rform.mode == 'empty' || rform.mode == 'view')"
      class="button button-fill"
      @click="rform.setMode('search')"
      v-tooltip="onlyIcons && $t('rform.search')"
    >
      <fa-icon icon="search" />
      <span v-if="!onlyIcons">{{ $t('rform.search') }}</span>
    </button>
    <div
      class="item-after"
      v-if="!(rform.mode == 'new' || rform.mode == 'edit')"
    >
      <div class="buttonset inline-options">
        <r-dropdown
          class="button button-fill"
          v-tooltip="onlyIcons && $t('rform.reports')"
        >
          <fa-icon icon="file-alt" />
          <span v-if="!onlyIcons">{{ $t('rform.reports') }}</span>
          <ul class="list">
            <slot name="reports"></slot>
          </ul>
        </r-dropdown>
        <r-dropdown
          v-if="acciones"
          class="button button-fill"
          v-tooltip="onlyIcons && $t('rform.actions')"
        >
          <fa-icon icon="toolbox" />
          <span v-if="!onlyIcons">{{ $t('rform.actions') }}</span>
          <ul class="list">
            <li @click="app.openChildWindow('importar', rformc.win, {
              backdrop: true,
              subtitle: rformc.title,
              model: rformc.dbAdapter,
              primary: rformc.primary
            })">Importar</li>
            <li @click="exportarCSV">Exportar</li>
            <slot name="actions"></slot>
          </ul>
        </r-dropdown>
      </div>
    </div>
    <slot name="after"></slot>
  </nav>
</template>
<script>
export default {
  inject: ["app", "rformc"],
  data: function () {
    return { rform: this.rformc, dropdownAccionesActive: false };
  },
  props: {
    onlyIcons: { type: Boolean, default: () => false },
    nuevo: {
      type: Boolean,
      default: () => true
    },
    editar: {
      type: Boolean,
      default: () => true
    },
    buscar: {
      type: Boolean,
      default: () => true
    },
    eliminar: {
      type: Boolean,
      default: () => true
    },
    acciones: {
      type: Boolean,
      default: () => true
    },
    cancelar: {
      type: Boolean,
      default: () => true
    },
  },
  methods: {
    exportarCSV() {
      var self = this;
      window.open(
        window.DB.server +
        self.rform.dbAdapter +
        "?" +
        window.$.param({
          action: "exportCSV",
          filter: self.rform.conditions,
          token: self.app.session.token
        }),
        "_blank"
      );
    }
  }
};
</script>
