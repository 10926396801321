<template>
  <div class="field item-input" :class="classMain">
    <slot name="media">
      <div class="item-media">
        <img v-if="image" :src="image" style="height:36px" />
      </div>
    </slot>
    <div class="item-inner">
      <label v-tooltip="help" class="item-title item-label" :for="name">{{label}}</label>
      <div class="item-input-wrap">
        <toggle-button
          ref="toggle"
          :value="parseInt(getValue())? true : false"
          @change="setValue($event.value?1:0); onChange($event); $emit('change', 'edit'); form.$emit('change', name, 'edit');"
          color="var(--color-primary)"
          :labels="labels"
          :sync="true"
          :width="width"
          :disabled="readonly||form.mode=='view'||form.mode=='empty'||(form.mode=='search'&&!searchable)"
          v-tooltip="form.errors[name]"
          :style="inputStyle"
          v-on="$listeners"
        />
      </div>
    </div>
  </div>
</template>
<script>
import FieldMixin from "./FieldMixin.vue";
export default {
  inject: ["rformc"],
  mixins: [FieldMixin],
  props: {
    /*rform: {},
    name: { type: String },
    width: { type: Number },
    required: { type: Boolean },
    readonly: { type: Boolean },
    searchable: { type: Boolean, default: false },
    
    help: {},*/
    label: { type: String },
    labels: { type: Object },
    "inline-label": { type: Boolean },
    maxlength: { type: Number },
    placeholder: { type: String },
    inputStyle: { type: String },
    image: { type: String }
  },
  computed: {
    classMain: function() {
      var classMain = {};
      classMain["inline-label"] = this.inlineLabel;
      classMain["readonly"] = this.readonly || this.form.readonly;
      classMain["mode-" + this.form.mode] = true;
      classMain["wrong"] = this.name in this.form.errors;
      return classMain;
    }
  },
  data: function() {
    return {
      form: this.rform ? this.rform : this.rformc
    };
  },
  methods: {
    /*getValue: function() {
      var self = this;
      return self.form.formData[self.name];
    },
    setValue: function(val) {
      var self = this;
      self.$set(self.form.formData, self.name, val);
    },
    onChange: function(e) {
      var self = this;
      self.setValue(e.value);
      if (self.form.mode == "search" && self.form.htSettings) {
        self.form.setFilter(self.form.getFilter());
      }
    }*/
  },
  mounted: function() {
    //window.console.log(this.form);
    var self = this;
    self.$refs.toggle.$on("change", function($event) {
      self.$emit("change", $event, "edit");
      self.form.$emit("change", self.name, "edit", $event);
    });

    self.$refs.toggle.$el.addEventListener("keydown", function(e) {
      if (e.key === "Enter") {
        if (e.shiftKey) self.focusPrevField(e.target);
        else self.focusNextField(e.target);
      }
    });
  }
};
</script>
<style>
input:focus + .v-switch-core {
  outline: 1px var(--color-primary) solid !important;
}
</style>
