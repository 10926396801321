<template>
  <vue-cropper
    v-if="visibleCropper"
    crossorigin
    ref="cropper"
    alt
    :crop="cropImage"
    v-bind="props"
    :src="src"
  />
</template>
<style>
.cropper-bg {
  background: white !important;
}
</style>
<script>
var debounce = function (func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
export default {
  components: { VueCropper },
  props: {
    src: {
      type: String,
    },
    width: {},
    height: {},
    opts: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: function () {
    let w = this.width;
    let h = this.height;
    return {
      ignoreNextUpdate: false,
      visibleCropper: !!this.src,
      props: {
        background: true,
        viewMode: 0,
        dragMode: "move",
        //aspectRatio: 1,
        //initialAspectRatio: 1,
        autoCropArea: 1,
        restore: false,
        modal: false,
        guides: false,
        highlight: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
        minContainerWidth: w,
        minContainerHeight: h,
        minCanvasWidth: w,
        minCanvasHeight: h,
        minCropBoxWidth: w,
        minCropBoxHeight: h,
      },
    };
  },
  watch: {
    src: function (newSrc, oldSrc) {
      var self = this;
      if (newSrc.startsWith("data:")) return;
      if (!newSrc) {
        self.clear();
      } else {
        self.ignoreNextUpdate = true;
        self.setImg(newSrc);
      }
    },
  },
  methods: {
    cropImage: debounce(function (e) {
      var self = this;
      if (self.ignoreNextUpdate) {
        self.ignoreNextUpdate = false;
        return;
      }
      let val = self.$refs.cropper
        .getCroppedCanvas({
          fillColor: "white",
          width: 600,
          height: 600,
          minWidth: 600,
          minHeight: 600,
          maxWidth: 600,
          maxHeight: 600,
        })
        .toDataURL();
      self.$emit("change", val);
    }, 250),
    getDataURL() {
      return this.$refs.cropper
        .getCroppedCanvas({
          fillColor: "white",
          width: 600,
          height: 600,
        })
        .toDataURL();
    },
    setImg(val) {
      var self = this;
      if (val && val.length > 8000000) {
        window.console.log(val.length);
        throw "Imagen demasiado grande";
      }
      self.visibleCropper = true;
      self.$nextTick(function () {
        self.$refs.cropper.replace(val);
      });
    },
    clear() {
      var self = this;
      self.visibleCropper = false;
      //self.$refs.cropper.clear();
    },
    rotate(rotationAngle) {
      this.$refs.cropper.rotate(rotationAngle);
    },
  },
};
</script>
