<template>
  <div class="field item-input" :class="classMain">
    <slot name="media">
      <div class="item-media">
        <img v-if="image" :src="image" style="height:36px" />
      </div>
    </slot>
    <div class="item-inner">
      <label v-tooltip="help" class="item-title item-label" :for="name">{{label}}</label>
      <div class="item-input-wrap">
        <select
          ref="input"
          :value="getValue()"
          @input="setValue($event.target.value)"
          @change="onChange"
          :class="{empty:!getValue()}"
          v-on="$listeners"
          :placeholder="placeholder"
          :maxlength="maxlength"
          :style="'width:'+width+';'+inputStyle"
          :disabled="(readonly&&form.mode!='search')||form.mode=='view'||form.mode=='empty'||(form.mode=='search'&&!searchable)"
          v-tooltip="form.errors[name]"
        >
          <option class="placeholder" selected value>{{placeholder}}</option>
          <option v-for="(o,k) in options" :key="name+'.'+k" :value="o">{{k}}</option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
import FieldMixin from "./FieldMixin.vue";
export default {
  inject: ["rformc"],
  mixins: [FieldMixin],
  props: {
    /*rform: {},
    name: { type: String },
    width: { type: String },
    required: { type: Boolean },
    readonly: { type: Boolean },
    searchable: { type: Boolean, default: false },
    help: {},*/
    label: { type: String },
    "inline-label": { type: Boolean },
    maxlength: { type: Number },
    placeholder: { type: String },
    inputStyle: { type: String },
    image: { type: String },
    options: {}
  },
  computed: {
    classMain: function() {
      var classMain = {};
      classMain["inline-label"] = this.inlineLabel;
      classMain["readonly"] = this.readonly || this.form.readonly;
      classMain["mode-" + this.form.mode] = true;
      classMain["wrong"] = this.name in this.form.errors;
      return classMain;
    }
  },
  data: function() {
    return {
      form: this.rform ? this.rform : this.rformc
    };
  },
  methods: {
    /*getValue: function() {
      var self = this;
      return self.form.formData[self.name];
    },
    setValue: function(val) {
      var self = this;
      self.$set(self.form.formData, self.name, val);
    },
    onChange: function() {
      var self = this;
      if (self.form.mode == "search" && self.form.htSettings) {
        self.form.setFilter(self.form.getFilter());
      }
    }*/
  },
  mounted: function() {
    //window.console.log(this.form);
  }
};
</script>
<style>
.field {
  position: absolute;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

label {
  color: #999;
  font-size: 11px;
}

.item-media {
  margin: 3px;
}
.inline-label .item-label + .item-input-wrap {
  margin-left: 8px;
  display: flex;
}

select {
  padding: 1px 3px;
  height: 1.5em;
  border-radius: 3px;
  /*border: none !important;
    border-bottom: solid 1px #CCC !important;*/
  border: 1px solid #ccc;
  position: relative;
}
.field.mode-search.searchable select {
  background-color: #ffffee;
}

select:disabled {
  border-color: transparent;
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
  /*background-color: #eee !important;*/
  /*border-bottom: 1px solid #ddd;*/
}
/*select:focus {
  background-color: rgba(0, 140, 186, 0.1) !important;
}*/
select::-ms-expand {
  display: none;
}

.item-input-wrap {
  position: relative;
}
.required:not(.wrong) .item-input-wrap::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
  height: 1px;
  background: #4b96e0 !important;
}
.wrong select {
  /*border: solid 1px #ff6a6a;*/
  background: var(--color-wrong);
}

option {
  color: black;
}
select.empty,
option.placeholder {
  color: #ccc;
}
</style>